import affinityGroups from './affinityGroups';
import * as constants from './constants';
import lookups from './lookups.json';
import geoPolygons from './geoPolygons.json';
import mortgageLookups from './mortgageLookups.json';
import enhancedRoofWindstormValues from './enhancedRoofWindstormValues.json';
lookups.affinityPartners = Object.keys(affinityGroups).map((ag) => {
    const { name, hideFromOffers } = affinityGroups[ag];
    return {
        id: ag,
        value: String(name || ag),
        hideFromOffers
    };
});
export const createLookupsById = (mortgageLookups) => mortgageLookups.reduce((acc, curr) => {
    if (curr.id) {
        acc[curr.id] = curr;
        return acc;
    }
    return acc;
}, {});
export const createLookupsByName = (mortgageLookups) => mortgageLookups.reduce((acc, curr) => {
    if (curr.possibleNames) {
        for (const name of curr.possibleNames) {
            acc[name] = curr;
        }
    }
    return acc;
}, {});
let currentState;
/**
 * @deprecated The values returned by setState, inThisState, getOptions, and getValue are untyped.
 * Consider using the `lookupsJson` object exported by this module or the constants exported
 * by the module such as `autoCoveragesToSave` and `homeCoveragesToSave` directly instead.
 */
export function setState(state) {
    currentState = state;
}
/**
 * @deprecated The values returned by setState, inThisState, getOptions, and getValue are untyped.
 * Consider using the `lookupsJson` object exported by this module or the constants exported
 * by the module such as `autoCoveragesToSave` and `homeCoveragesToSave` directly instead.
 */
export function inThisState(name) {
    return constants[name] && constants[name][currentState];
}
/**
 * @deprecated The values returned by setState, inThisState, getOptions, and getValue are untyped.
 * Consider using the `lookupsJson` object exported by this module or the constants exported
 * by the module such as `autoCoveragesToSave` and `homeCoveragesToSave` directly instead.
 */
export function getOptions(name, stateIn, lookupsObj, policyType) {
    const state = stateIn || currentState;
    // uses lookupsObj if passed, in other case uses lookups
    const lookupsObject = lookupsObj || lookups;
    if (lookupsObject[`${name}State`] && lookupsObject[`${name}State`][state]) {
        if (policyType) {
            return lookupsObject[`${name}State`][state][policyType];
        }
        return lookupsObject[`${name}State`][state];
    }
    return lookupsObject[name];
}
/**
 * @deprecated The values returned by setState, inThisState, getOptions, and getValue are untyped.
 * Consider using the `lookupsJson` object exported by this module or the constants exported
 * by the module such as `autoCoveragesToSave` and `homeCoveragesToSave` directly instead.
 */
export function getValue(name, idOrObj, stateIn, lookupsObj) {
    if (idOrObj === null || idOrObj === undefined) {
        return '';
    }
    const state = stateIn || currentState;
    // uses lookupsObj if passed, in other case uses lookups
    const lookupsObject = lookupsObj || lookups;
    // If we have a primitive value as second parameter we just
    // return the lookup with that value, if it's an object we use
    // idOrObj[name] to retrieve the value, this is a helper for those
    // cases where the key is equals to the object property
    let lookup;
    const lookupArray = lookupsObject[`${name}State`] && lookupsObject[`${name}State`][state]
        ? lookupsObject[`${name}State`][state]
        : lookupsObject[name];
    if (typeof idOrObj !== 'object') {
        lookup = lookupArray.find((v) => v.id === idOrObj);
    }
    else {
        lookup = lookupArray.find((v) => v.id === idOrObj[name]);
    }
    return (lookup && lookup.value) || '';
}
/* This function is a placeholder for being able to dynamically select which states
 * will have be used for the MVR service, in anticipation that we will want to make
 * different selections based upon different circumstances
 */
export function getMVRStates() {
    return constants.mvrStates;
}
export function getFinancialResponsibilityTier(primaryInsuranceScore, secondaryInsuranceScore, altCredit) {
    let insuranceScore = primaryInsuranceScore;
    if (altCredit) {
        // only use primary, different algorithm:
        if (insuranceScore <= 389) {
            return 'A-1';
        }
        if (insuranceScore <= 448) {
            return 'B-1';
        }
        if (insuranceScore <= 498) {
            return 'C-1';
        }
        if (insuranceScore <= 544) {
            return 'D-1';
        }
        if (insuranceScore <= 586) {
            return 'E-1';
        }
        if (insuranceScore <= 624) {
            return 'F-1';
        }
        if (insuranceScore <= 659) {
            return 'G-1';
        }
        if (insuranceScore <= 690) {
            return 'H-1';
        }
        if (insuranceScore <= 718) {
            return 'I-1';
        }
        if (insuranceScore <= 744) {
            return 'J-1';
        }
        if (insuranceScore <= 769) {
            return 'K-1';
        }
        if (insuranceScore <= 794) {
            return 'L-1';
        }
        if (insuranceScore <= 824) {
            return 'M-1';
        }
        if (insuranceScore <= 999) {
            return 'N-1';
        }
        return;
    }
    if (secondaryInsuranceScore) {
        // there is a coapplicant, so we construct a combined score
        insuranceScore += secondaryInsuranceScore;
        // divide by two
        insuranceScore /= 2;
    }
    if (insuranceScore <= 283) {
        return 'A-1';
    }
    if (insuranceScore <= 305) {
        return 'B-1';
    }
    if (insuranceScore <= 324) {
        return 'C-1';
    }
    if (insuranceScore <= 342) {
        return 'D-1';
    }
    if (insuranceScore <= 360) {
        return 'E-1';
    }
    if (insuranceScore <= 381) {
        return 'F-1';
    }
    if (insuranceScore <= 402) {
        return 'G-1';
    }
    if (insuranceScore <= 427) {
        return 'H-1';
    }
    if (insuranceScore <= 447) {
        return 'I-1';
    }
    if (insuranceScore <= 467) {
        return 'J-1';
    }
    if (insuranceScore <= 485) {
        return 'K-1';
    }
    if (insuranceScore <= 508) {
        return 'L-1';
    }
    if (insuranceScore <= 537) {
        return 'M-1';
    }
    if (insuranceScore <= 606) {
        return 'N-1';
    }
    if (insuranceScore <= 730) {
        return 'O-1';
    }
    if (insuranceScore <= 802) {
        return 'P-1';
    }
    if (insuranceScore <= 905) {
        return 'Q-1';
    }
    if (insuranceScore <= 999) {
        return 'R-1';
    }
    throw new Error('Invalid insurance score');
}
export function getInsuranceScoreGroup(insuranceScore) {
    if (insuranceScore <= 231) {
        return 1;
    }
    if (insuranceScore <= 250) {
        return 2;
    }
    if (insuranceScore <= 266) {
        return 3;
    }
    if (insuranceScore <= 279) {
        return 4;
    }
    if (insuranceScore <= 291) {
        return 5;
    }
    if (insuranceScore <= 297) {
        return 6;
    }
    if (insuranceScore <= 305) {
        return 7;
    }
    if (insuranceScore <= 313) {
        return 8;
    }
    if (insuranceScore <= 320) {
        return 9;
    }
    if (insuranceScore <= 326) {
        return 10;
    }
    if (insuranceScore <= 332) {
        return 11;
    }
    if (insuranceScore <= 338) {
        return 12;
    }
    if (insuranceScore <= 343) {
        return 13;
    }
    if (insuranceScore <= 349) {
        return 14;
    }
    if (insuranceScore <= 354) {
        return 15;
    }
    if (insuranceScore <= 360) {
        return 16;
    }
    if (insuranceScore <= 366) {
        return 17;
    }
    if (insuranceScore <= 372) {
        return 18;
    }
    if (insuranceScore <= 378) {
        return 19;
    }
    if (insuranceScore <= 384) {
        return 20;
    }
    if (insuranceScore <= 390) {
        return 21;
    }
    if (insuranceScore <= 396) {
        return 22;
    }
    if (insuranceScore <= 402) {
        return 23;
    }
    if (insuranceScore <= 408) {
        return 24;
    }
    if (insuranceScore <= 414) {
        return 25;
    }
    if (insuranceScore <= 421) {
        return 26;
    }
    if (insuranceScore <= 427) {
        return 27;
    }
    if (insuranceScore <= 434) {
        return 28;
    }
    if (insuranceScore <= 441) {
        return 29;
    }
    if (insuranceScore <= 448) {
        return 30;
    }
    if (insuranceScore <= 452) {
        return 31;
    }
    if (insuranceScore <= 458) {
        return 32;
    }
    if (insuranceScore <= 464) {
        return 33;
    }
    if (insuranceScore <= 471) {
        return 34;
    }
    if (insuranceScore <= 479) {
        return 35;
    }
    if (insuranceScore <= 487) {
        return 36;
    }
    if (insuranceScore <= 495) {
        return 37;
    }
    if (insuranceScore <= 503) {
        return 38;
    }
    if (insuranceScore <= 512) {
        return 39;
    }
    if (insuranceScore <= 522) {
        return 40;
    }
    if (insuranceScore <= 531) {
        return 41;
    }
    if (insuranceScore <= 541) {
        return 42;
    }
    if (insuranceScore <= 551) {
        return 43;
    }
    if (insuranceScore <= 562) {
        return 44;
    }
    if (insuranceScore <= 575) {
        return 45;
    }
    if (insuranceScore <= 590) {
        return 46;
    }
    if (insuranceScore <= 606) {
        return 47;
    }
    if (insuranceScore <= 626) {
        return 48;
    }
    if (insuranceScore <= 657) {
        return 49;
    }
    if (insuranceScore <= 999) {
        return 50;
    }
    throw new Error('Invalid insurance score');
}
export { affinityGroups, enhancedRoofWindstormValues, lookups as lookupsJson, mortgageLookups, geoPolygons };
export { default as agencyInformation } from './agencyInformation';
export { default as insuranceProviderList } from './insuranceProviderList';
export * from './constants';
