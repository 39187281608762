import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    height: 34,
    alignItems: 'flex-end',
    fontWeight: 300,
    paddingLeft: 8
  },
  footerContainer: {
    height: 98,
    background: theme.colorPalette.beige_10,
    boxShadow: '0px -1px 3px 0px rgba(0,0,0,0.2)'
  },
  footerAlert: {
    background: theme.colorPalette.orange_30
  },
  floatingContainer: {
    position: 'fixed',
    left: 0,
    width: '100%',
    bottom: 0,
    zIndex: 99
  },
  footerContent: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    width: '100%',
    maxWidth: 1280,
    height: 98,
    margin: '0 auto'
  },
  btn: {
    padding: '0 47px',
    minWidth: 164
  },
  btnLabel: {
    fontWeight: 600,
    fontSize: 16
  },
  form: {
    display: 'flex',
    marginRight: 24
  },
  formBtn: {
    backgroundColor: theme.colorPalette.orange_10,
    minWidth: 164,
    '&.MuiButton-contained.Mui-disabled': {
      color: '#fff',
      backgroundColor: theme.colorPalette.orange_10_op_70
    }
  },
  copyLink: {
    paddingLeft: 8
  },
  emailBtnLabel: {
    fontWeight: 500,
    fontSize: 14,
    paddingLeft: 2
  },
  emailField: {
    paddingRight: 14,
    minHeight: 67
  },
  checkbox: {
    paddingRight: 10
  }
}));

export default useStyles;
