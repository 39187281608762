import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import {
  getOptions,
  HighestEducation,
  lookupsJson,
  windstormMitigationDiscountStates,
  stormShuttersStates,
  hideHighestEducationStates
} from '@ourbranch/lookups';

import People from 'common/components/people';
import { useCurrentState } from 'common/hooks/useCurrentState';
import CollapsibleCard from 'core/components/collapsible-card';
import { FormField } from 'core/components/form';
import Field from 'core/components/form/form.v2';
import { Label } from 'core/components/label';
import { LabelTooltip } from 'core/components/label-tooltip';
import Section from 'core/components/section';
import { useFormikContext } from 'formik';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { addDays } from 'date-fns';
import withDatePicker from '../../../../core/components/with-date-picker';
import { AddMortgage, MortgageItem } from './mortgage-details';
import config from '../../../../aws-exports';
import styles from './detail.styles';

const id = 'home';

function generateZillowLink({ address, city, state, zip }) {
  return `https://www.zillow.com/homes/${address.replaceAll(' ', '-')}-${city.replaceAll(
    ' ',
    '-'
  )},-${state}-${zip}_rb`;
}

const alWindstormToolTip = () => {
  return (
    <>
      <div>Definitions:</div>
      <br />
      <div>
        <strong>Retrofit</strong> - Roof has been retrofitted to conform to hurricane mitigation measures under the
        Fortified: Existing Homes program published by the Institute for Business and Home Safety
      </div>
      <br />
      <div>
        <strong>FFSLS</strong> - Fortified For Safer Living Standards, published by the Institute for Business and Home
        Safety
      </div>
      <br />
      <div>
        <strong>IRC</strong> - 2006 International Residential Code® (IRC) for One-and Two-Family Dwellings published by
        the International Code Council
      </div>
    </>
  );
};

const Detail = memo(({ disabled, classes }) => {
  const { values, setFieldValue } = useFormikContext();
  const { home } = values;
  const state = useCurrentState(values);

  useEffect(() => {
    if (home.mortgageDetails.length > 0) {
      if (!home.mortgageDetails.find((item) => item.primary)) {
        setFieldValue(`${id}.mortgageDetails.0`, { ...home.mortgageDetails[0], primary: true });
      }
    } else {
      setFieldValue(`primaryMortgageDetail.mortgageHolderAddress`, undefined);
      setFieldValue(`primaryMortgageDetail.mortgageHolderName`, undefined);
      setFieldValue(`primaryMortgageDetail.mortgageHolderId`, undefined);
      setFieldValue(`primaryMortgageDetail.loanNumber`, undefined);
    }
  }, [home.mortgageDetails, home.mortgageDetails.length, setFieldValue]);

  if (!home) {
    return null;
  }
  const showStormShutters = stormShuttersStates.includes(home.homeLocation.state);
  return (
    <Section title="Home detail" type="SubSection">
      <CollapsibleCard
        initialOpen
        content={
          <div>
            <Label type="infoSubtitle">
              {`${home.homeLocation.address} ${home.homeLocation.city} ${home.homeLocation.state} ${home.homeLocation.zip}`}
            </Label>
            <Grid container spacing={10}>
              <Grid item>
                <a
                  rel="noreferrer noopener"
                  target="_blank"
                  href={`https://maps.googleapis.com/maps/api/streetview?size=1200x800&location=${home.latitude},${home.longitude}&fov=80&key=${config.googleGeocoderKey}`}
                  className={classes.streetMapText}
                >
                  See this property on Google Maps Street View
                </a>
              </Grid>
              <Grid item>
                <a
                  rel="noreferrer noopener"
                  target="_blank"
                  href={generateZillowLink(home.homeLocation)}
                  className={classes.streetMapText}
                >
                  See this property on Zillow
                </a>
              </Grid>
            </Grid>
          </div>
        }
      >
        <Grid container justify="space-around" className={classes.containerDark}>
          <Grid container spacing={2} justify="space-around" alignItems="flex-start">
            <Grid item xs={12}>
              <Label type="formSubTitleLight">Residents</Label>
            </Grid>
            <FormField
              name={`${id}.ownershipStatus`}
              type="select"
              label="Ownership"
              mode="light"
              options={getOptions('ownershipStatus')}
              xs={6}
            />
            <FormField
              name={`${id}.purchaseDate`}
              type="date"
              label="House Purchased"
              mode="light"
              xs={3}
              disableFuture={false}
              maxDate={addDays(new Date(), 60)}
            />
            <FormField
              name={`${id}.numOccupants`}
              type="numeric"
              label="Residents"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={3}
            />
            {!hideHighestEducationStates.includes(state) && (
              <FormField
                name="highestEducation"
                type="value"
                label="Highest Education of Residents (non-editable)"
                value={HighestEducation[values.highestEducation] || 'N/A'}
                xs={6}
              />
            )}
            <FormField
              name="oldestResident"
              type="value"
              label="Age of Oldest resident (non-editable)"
              value={values.oldestResident || 'N/A'}
              xs={hideHighestEducationStates.includes(state) ? 12 : 6}
            />
            <Grid item xs={12}>
              <Label type="formSubTitleLight">Property</Label>
            </Grid>
            <FormField
              name={`${id}.yearBuilt`}
              type="numeric"
              label="Built in"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={3}
            />
            <FormField
              name={`${id}.typeOfHome`}
              type="select"
              label="Home Type"
              mode="light"
              options={getOptions('typeOfHome')}
              xs={3}
            />
            <FormField
              name={`${id}.numFamiliesInDwelling`}
              type="numeric"
              label="Families in Dwelling"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={3}
            />
            <FormField
              name={`${id}.homeQuality`}
              type="select"
              label="Home Quality"
              mode="light"
              options={getOptions('homeQuality')}
              xs={3}
            />
            <FormField
              name={`${id}.sqFt`}
              type="numeric"
              label="Home Square Footage"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={6}
            />
            <FormField
              name={`${id}.numStories`}
              type="numeric"
              label="Number of Stories"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={3}
            />
            <FormField
              name={`${id}.constructionType`}
              type="select"
              label="Construction Type"
              mode="light"
              options={getOptions('constructionType')}
              xs={3}
            />
            <FormField
              name={`${id}.numHalfBathrooms`}
              type="numeric"
              label="Number of Half Bathrooms"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={4}
            />
            <FormField
              name={`${id}.numFullBathrooms`}
              type="numeric"
              label="Number of Full Bathrooms"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={4}
            />
            <FormField
              name={`${id}.numFireplaces`}
              type="numeric"
              label="Number of Fireplaces"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={4}
            />
            <FormField
              name={`${id}.numWoodBurningStoves`}
              type="numeric"
              label="Number of Wood Stoves"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={3}
            />
            <FormField
              name={`${id}.basementType`}
              type="select"
              label="Basement Type"
              mode="light"
              options={getOptions('basementType')}
              xs={3}
            />
            <FormField
              name={`${id}.basementSqFt`}
              type="numeric"
              label="Basement Square Footage"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={6}
            />
            <FormField
              name={`${id}.exteriorWallType`}
              type="select"
              label="Exterior Wall Type"
              mode="light"
              options={getOptions('exteriorWallType')}
              xs={6}
            />
            <FormField
              name={`${id}.garageType`}
              type="select"
              label="Garage Type"
              mode="light"
              options={getOptions('garageType')}
              xs={3}
            />
            <FormField
              name={`${id}.garageCarCapacity`}
              type="numeric"
              label="Garage's car space"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={3}
            />
            <FormField
              name={`${id}.roofYear`}
              type="numeric"
              label="Roof Year"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={4}
            />
            <FormField
              name={`${id}.roofType`}
              type="select"
              label="Roof Type"
              mode="light"
              options={getOptions('roofType')}
              xs={4}
            />
            <FormField
              name={`${id}.roofShape`}
              type="select"
              label="Roof Shape"
              mode="light"
              options={getOptions('roofShape')}
              xs={4}
            />
            <FormField
              name={`${id}.'fpc`}
              type="value"
              label="Fire Protection Class (non-editable)"
              value={home.fpc || 'N/A'}
              xs={6}
            />
            <FormField
              name={`${id}.wildfireHazardScore`}
              type="value"
              label="Wildfire Hazard Score (non-editable)"
              value={home.wildfireHazardScore || 'N/A'}
              xs={6}
            />
            <FormField
              name={`${id}.dtoName`}
              type="value"
              label="Distance to Ocean (non-editable)"
              value={home.dtoName || 'N/A'}
              xs={4}
            />
            <FormField
              name={`${id}.dtbowLabel`}
              type="value"
              label="Nearest BOW (non-editable)"
              value={home.dtbowLabel || 'N/A'}
              xs={4}
            />
            <FormField
              name={`${id}.dtbowName`}
              type="value"
              label="Distance to BOW (non-editable)"
              value={home.dtbowName || 'N/A'}
              xs={4}
            />
            {showStormShutters && (
              <FormField
                name={`${id}.stormShutters`}
                type="checkbox"
                label="Storm shutters on all exterior windows?"
                mode="light"
                xs={12}
              />
            )}
          </Grid>
        </Grid>
        {windstormMitigationDiscountStates[home.homeLocation.state] && (
          <Grid item xs={12} container alignItems="flex-start" className={classes.windstormDetailsContainer}>
            <Grid item xs={12} className={classes.windstormDetailsSectionLabel}>
              <Label type="formSubTitleLight">Windstorm Details</Label>
            </Grid>
            <Grid item xs={6}>
              <LabelTooltip
                label="Windstorm Mitigation Certification"
                mode="light"
                tooltip={{
                  label: 'More Info',
                  onHoverText: alWindstormToolTip()
                }}
              >
                <Field
                  name={`${id}.windstormMitigationLevel`}
                  className={classes.windstormMitigationLevel}
                  type="select"
                  mode="light"
                  options={lookupsJson.windstormMitigationLevel}
                />
              </LabelTooltip>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} container className={classes.mortgagesContainer}>
          <People
            classes={{ container: classes.container, rightValue: classes.sectionRightValue }}
            disabled={disabled}
            id={`${id}.mortgageDetails`}
            person={MortgageItem}
            type="InnerSection"
            addForm={AddMortgage}
            title="Mortgage Details"
            singular="Mortgage"
            plural="Mortgages"
          />
        </Grid>
      </CollapsibleCard>
    </Section>
  );
});

Detail.propTypes = {
  disabled: PropTypes.bool,
  classes: PropTypes.object.isRequired
};

Detail.defaultProps = {
  disabled: false
};

export default flowRight(withStyles(styles), withDatePicker)(Detail);
