import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import MUIRadio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Tooltip } from 'core/components/label-tooltip';
import styles from './radio.styles';

const Radio = ({
  id,
  name,
  classes,
  value,
  style,
  mode,
  onChange,
  options,
  error,
  helperText,
  defaultValue,
  disabled,
  className,
  label,
  tooltipLabel,
  ...props
}) => (
  <FormControl disabled={disabled} id={id} className={classNames(classes.formControl, className)} {...props}>
    <RadioGroup
      id={id}
      name={name}
      className={classes.container}
      value={value || defaultValue}
      style={style}
      onChange={onChange}
    >
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={
            <MUIRadio
              checked={value === option.value}
              classes={{
                root: mode ? classes[`${mode}-radioRoot`] : null,
                disabled: mode ? classes[`${mode}-radioRootDisabled`] : null
              }}
            />
          }
          label={
            <>
              {label || option.label}
              {option.tooltipText && tooltipLabel && (
                <Tooltip text={option.tooltipText} placement="bottom" label={tooltipLabel} />
              )}
            </>
          }
          classes={{
            root: mode ? classes[`${mode}-radioRoot`] : null,
            label: mode ? classes[`${mode}-radioLabel`] : null,
            disabled: mode ? classes[`${mode}-radioLabelDisabled`] : null
          }}
        />
      ))}
    </RadioGroup>
    {error && (
      <FormHelperText classes={{ root: classes.helperText }} id={id} error={error}>
        {helperText}
      </FormHelperText>
    )}
  </FormControl>
);
Radio.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      tooltipText: PropTypes.string
    })
  ).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  mode: PropTypes.oneOf([null, 'light', 'dark']),
  error: PropTypes.bool,
  helperText: PropTypes.any,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.node,
  tooltipLabel: PropTypes.string
};

Radio.defaultProps = {
  value: undefined,
  style: {},
  mode: null,
  error: false,
  helperText: undefined,
  defaultValue: undefined,
  disabled: false,
  className: '',
  label: '',
  tooltipLabel: null
};

export default withStyles(styles)(Radio);
