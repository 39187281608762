const insuranceProviderList = [
    { id: '21st Century Insurance', value: '21st Century Insurance' },
    { id: 'ACCC Insurance Co.', value: 'ACCC Insurance Co.' },
    { id: 'Access Home Insurance Co.', value: 'Access Home Insurance Co.' },
    { id: 'Accident Insurance Co.', value: 'Accident Insurance Co.' },
    { id: 'ACE', value: 'ACE' },
    { id: 'ACUITY A Mutual Insurance Co.', value: 'ACUITY A Mutual Insurance Co.' },
    { id: 'Aegis Security Insurance Co.', value: 'Aegis Security Insurance Co.' },
    { id: 'Agency Insurance Co. of MD Inc', value: 'Agency Insurance Co. of MD Inc' },
    { id: 'Agents Mutual Insurance Co.', value: 'Agents Mutual Insurance Co.' },
    { id: 'AgWorkers Insurance', value: 'AgWorkers Insurance' },
    { id: 'AIG', value: 'AIG' },
    { id: 'AIG Insurance Co. - PR', value: 'AIG Insurance Co. - PR' },
    { id: 'AIG Property Casualty U.S. Inc', value: 'AIG Property Casualty U.S. Inc' },
    { id: 'Aioi Nissay Dowa Ins Co. Ltd.', value: 'Aioi Nissay Dowa Ins Co. Ltd.' },
    { id: "Alamance Farmers' Mutl Ins Co.", value: "Alamance Farmers' Mutl Ins Co." },
    { id: 'Alfa Mutual', value: 'Alfa Mutual' },
    { id: 'Alinsco Insurance Co.', value: 'Alinsco Insurance Co.' },
    { id: 'Allegany Insurance', value: 'Allegany Insurance' },
    { id: 'Alliance United', value: 'Alliance United' },
    { id: 'Allianz Global Risks US Ins Co', value: 'Allianz Global Risks US Ins Co' },
    { id: 'ALLIED', value: 'ALLIED' },
    { id: 'Allied Insurance Co of America', value: 'Allied Insurance Co of America' },
    { id: 'Allied Trust Insurance Co.', value: 'Allied Trust Insurance Co.' },
    { id: 'Allied World Assurance Co.', value: 'Allied World Assurance Co.' },
    { id: 'Allstate', value: 'Allstate' },
    { id: 'Amer Integrity Ins Co FL', value: 'Amer Integrity Ins Co FL' },
    { id: 'Amer Modern', value: 'Amer Modern' },
    { id: 'American Access Casualty Co.', value: 'American Access Casualty Co.' },
    { id: 'American Alliance Casualty Co.', value: 'American Alliance Casualty Co.' },
    { id: 'American European', value: 'American European' },
    { id: 'American Family', value: 'American Family' },
    { id: 'American Farmers & Ranchers', value: 'American Farmers & Ranchers' },
    { id: 'American National Property & Casualty', value: 'American National Property & Casualty' },
    { id: 'American Risk Insurance Co.', value: 'American Risk Insurance Co.' },
    { id: 'American Sentinel Insurance Co', value: 'American Sentinel Insurance Co' },
    { id: 'American Traditions Ins Co.', value: 'American Traditions Ins Co.' },
    { id: 'Americas Insurance Company', value: 'Americas Insurance Company' },
    { id: 'Ameriprise', value: 'Ameriprise' },
    { id: 'AmeriTrust', value: 'AmeriTrust' },
    { id: 'Amica', value: 'Amica' },
    { id: 'Anchor Insurance', value: 'Anchor Insurance' },
    { id: 'Anchor Insurance Holdings Inc.', value: 'Anchor Insurance Holdings Inc.' },
    { id: 'Andover Companies', value: 'Andover Companies' },
    { id: 'Antilles Insurance Co.', value: 'Antilles Insurance Co.' },
    { id: 'Arbella Insurance', value: 'Arbella Insurance' },
    { id: 'Arch Insurance Co.', value: 'Arch Insurance Co.' },
    { id: 'Armed Forces Insurance', value: 'Armed Forces Insurance' },
    { id: 'ASI', value: 'ASI' },
    { id: 'Aspen', value: 'Aspen' },
    { id: 'Aspire General Insurance Co.', value: 'Aspire General Insurance Co.' },
    { id: 'Associated Mutual Ins Coop', value: 'Associated Mutual Ins Coop' },
    { id: 'AssuranceAmerica', value: 'AssuranceAmerica' },
    { id: 'Assurant', value: 'Assurant' },
    { id: 'Atlantic American / Delta Group', value: 'Atlantic American / Delta Group' },
    { id: 'Atlas Financial Holdings Inc.', value: 'Atlas Financial Holdings Inc.' },
    { id: 'Austin Mutual Insurance Co.', value: 'Austin Mutual Insurance Co.' },
    { id: 'Auto Club', value: 'Auto Club' },
    { id: 'Auto Club South Insurance Co.', value: 'Auto Club South Insurance Co.' },
    { id: 'Auto-Owners', value: 'Auto-Owners' },
    { id: 'Avatar P&C Insurance Co.', value: 'Avatar P&C Insurance Co.' },
    { id: 'Aventus Insurance Co.', value: 'Aventus Insurance Co.' },
    { id: 'AXIS', value: 'AXIS' },
    { id: 'Badger Mutual Insurance Co.', value: 'Badger Mutual Insurance Co.' },
    { id: 'Baldwin Mutual Insurance Co.', value: 'Baldwin Mutual Insurance Co.' },
    { id: 'Bank of America Corporation', value: 'Bank of America Corporation' },
    { id: 'Bankers Insurance', value: 'Bankers Insurance' },
    { id: 'Barnstable', value: 'Barnstable' },
    { id: 'Bear River Mutl Ins Co Inc', value: 'Bear River Mutl Ins Co Inc' },
    { id: 'Bedford Grange Mutual Ins Co.', value: 'Bedford Grange Mutual Ins Co.' },
    { id: 'Berkley Insurance Co.', value: 'Berkley Insurance Co.' },
    { id: 'Berkley Regional Insurance Co.', value: 'Berkley Regional Insurance Co.' },
    { id: 'BIC Holdings LLC', value: 'BIC Holdings LLC' },
    { id: 'BlueShore Insurance Co.', value: 'BlueShore Insurance Co.' },
    { id: 'Bremen Farmers Mutual Ins Co.', value: 'Bremen Farmers Mutual Ins Co.' },
    { id: 'Brethren Mutl Ins Co The', value: 'Brethren Mutl Ins Co The' },
    { id: 'Briar Creek Mutual Ins Co.', value: 'Briar Creek Mutual Ins Co.' },
    { id: 'Broome Co-operative Ins Co.', value: 'Broome Co-operative Ins Co.' },
    { id: 'Buckeye Insurance', value: 'Buckeye Insurance' },
    { id: 'Builders Mutual', value: 'Builders Mutual' },
    { id: 'Bunker Hill Insurance Co.', value: 'Bunker Hill Insurance Co.' },
    { id: 'California Casualty', value: 'California Casualty' },
    { id: 'California Mutual Insurance Co', value: 'California Mutual Insurance Co' },
    { id: 'Callicoon Co-operative Ins Co.', value: 'Callicoon Co-operative Ins Co.' },
    { id: 'Cameron Mutual', value: 'Cameron Mutual' },
    { id: 'Canopius US Insurance Inc.', value: 'Canopius US Insurance Inc.' },
    { id: 'Capital Insurance', value: 'Capital Insurance' },
    { id: 'Capitol Preferred Insurance Co', value: 'Capitol Preferred Insurance Co' },
    { id: 'Carolina Farmers Mutual Ins Co', value: 'Carolina Farmers Mutual Ins Co' },
    { id: 'Carolina Motor Club Inc.', value: 'Carolina Motor Club Inc.' },
    { id: 'Castle Key Insurance Co.', value: 'Castle Key Insurance Co.' },
    { id: 'Casualty Corp. of America Inc.', value: 'Casualty Corp. of America Inc.' },
    { id: 'Casualty Underwriters Ins Co.', value: 'Casualty Underwriters Ins Co.' },
    { id: 'Catalina Holdings (Bermuda) Ltd.', value: 'Catalina Holdings (Bermuda) Ltd.' },
    { id: 'Celina Insurance', value: 'Celina Insurance' },
    { id: 'CEM Insurance Co.', value: 'CEM Insurance Co.' },
    { id: 'Centauri Insurance', value: 'Centauri Insurance' },
    { id: 'Center Mutual Insurance Co.', value: 'Center Mutual Insurance Co.' },
    { id: 'Central Co-operative Ins Co.', value: 'Central Co-operative Ins Co.' },
    { id: 'Central Insurance Companies', value: 'Central Insurance Companies' },
    { id: 'Centre County Mutl Fire Ins Co', value: 'Centre County Mutl Fire Ins Co' },
    { id: 'Century Ins Co. (Guam) Ltd.', value: 'Century Ins Co. (Guam) Ltd.' },
    { id: 'Century Mutual Insurance Co.', value: 'Century Mutual Insurance Co.' },
    { id: 'Chautauqua Patrons Ins Co.', value: 'Chautauqua Patrons Ins Co.' },
    { id: 'Chubb', value: 'Chubb' },
    { id: 'Church Mutual', value: 'Church Mutual' },
    { id: 'Cincinnati Insurance', value: 'Cincinnati Insurance' },
    { id: 'Cities & Villages Mutl Ins Co.', value: 'Cities & Villages Mutl Ins Co.' },
    { id: 'Citizens Property Ins Corp.', value: 'Citizens Property Ins Corp.' },
    { id: 'Citizens United Recpl Exchange', value: 'Citizens United Recpl Exchange' },
    { id: 'Clarendon National Ins Co.', value: 'Clarendon National Ins Co.' },
    { id: 'Clear Blue Insurance', value: 'Clear Blue Insurance' },
    { id: 'CO Farm Bureau Mutual Ins Co.', value: 'CO Farm Bureau Mutual Ins Co.' },
    { id: 'Coastal American Insurance Co.', value: 'Coastal American Insurance Co.' },
    { id: 'Colonial', value: 'Colonial' },
    { id: 'Columbia Insurance', value: 'Columbia Insurance' },
    { id: 'Commonwealth Casualty Co.', value: 'Commonwealth Casualty Co.' },
    { id: 'Community Ins Co. (PA)', value: 'Community Ins Co. (PA)' },
    { id: 'Concord Group Insurance', value: 'Concord Group Insurance' },
    { id: 'Conifer Holdings Inc.', value: 'Conifer Holdings Inc.' },
    { id: 'Consumers County Mutual', value: 'Consumers County Mutual' },
    { id: 'Continental Corp.', value: 'Continental Corp.' },
    { id: 'Cooperativa de Seguros', value: 'Cooperativa de Seguros' },
    { id: 'Cornerstone National Ins Co.', value: 'Cornerstone National Ins Co.' },
    { id: 'COUNTRY Financial', value: 'COUNTRY Financial' },
    { id: 'Country-Wide Insurance Co.', value: 'Country-Wide Insurance Co.' },
    { id: 'Crestbrook Insurance Co.', value: 'Crestbrook Insurance Co.' },
    { id: 'Crum & Forster Holdings Corp.', value: 'Crum & Forster Holdings Corp.' },
    { id: 'CSAA', value: 'CSAA' },
    { id: 'CSE Insurance', value: 'CSE Insurance' },
    { id: 'Cumberland Group', value: 'Cumberland Group' },
    { id: 'Cypress Group Holdings, Inc.', value: 'Cypress Group Holdings, Inc.' },
    { id: 'DB Insurance Co. Ltd.', value: 'DB Insurance Co. Ltd.' },
    { id: 'De Smet Farm Mutl Ins Co of SD', value: 'De Smet Farm Mutl Ins Co of SD' },
    { id: 'Delaware Grange Mutual Ins Co.', value: 'Delaware Grange Mutual Ins Co.' },
    { id: 'Delta Lloyds Ins Co of Houston', value: 'Delta Lloyds Ins Co of Houston' },
    { id: 'Discovery Insurance Co.', value: 'Discovery Insurance Co.' },
    { id: 'Donegal Mutual Insurance Co.', value: 'Donegal Mutual Insurance Co.' },
    { id: 'Dryden Mutual Insurance Co.', value: 'Dryden Mutual Insurance Co.' },
    { id: 'DTRIC Insurance Co. Ltd.', value: 'DTRIC Insurance Co. Ltd.' },
    { id: 'Eagle Point Mutual Ins Co.', value: 'Eagle Point Mutual Ins Co.' },
    { id: 'ECM Insurance', value: 'ECM Insurance' },
    { id: 'Elephant Insurance Co.', value: 'Elephant Insurance Co.' },
    { id: 'Ellington Mutual Insurance Co.', value: 'Ellington Mutual Insurance Co.' },
    { id: 'EMC Insurance Group Inc.', value: 'EMC Insurance Group Inc.' },
    { id: 'EMC P&C Co.', value: 'EMC P&C Co.' },
    { id: 'Employers Mutual Casualty Co.', value: 'Employers Mutual Casualty Co.' },
    { id: 'Encompass', value: 'Encompass' },
    { id: 'Erie', value: 'Erie' },
    { id: 'Erie and Niagara Insurance', value: 'Erie and Niagara Insurance' },
    { id: 'Essentia Insurance Co.', value: 'Essentia Insurance Co.' },
    { id: 'Esurance', value: 'Esurance' },
    { id: 'Evanston Insurance Co.', value: 'Evanston Insurance Co.' },
    { id: 'Everest Re', value: 'Everest Re' },
    { id: 'Excalibur National Ins Co.', value: 'Excalibur National Ins Co.' },
    { id: 'Falcon Insurance Co.', value: 'Falcon Insurance Co.' },
    { id: 'Farm Bureau Financial Services', value: 'Farm Bureau Financial Services' },
    { id: 'Farm Bureau Mutl Ins Co Idaho', value: 'Farm Bureau Mutl Ins Co Idaho' },
    { id: 'Farm Bureau Mutl Ins Co. of AR', value: 'Farm Bureau Mutl Ins Co. of AR' },
    { id: 'Farm Crdt Sys Assn Captive Ins', value: 'Farm Crdt Sys Assn Captive Ins' },
    { id: 'Farm Family Insurance Companies', value: 'Farm Family Insurance Companies' },
    { id: 'Farmers', value: 'Farmers' },
    { id: 'Farmers & Mechanics Mutl Ins', value: 'Farmers & Mechanics Mutl Ins' },
    { id: 'Farmers & Mrchnt Mutl Fire Ins', value: 'Farmers & Mrchnt Mutl Fire Ins' },
    { id: 'Farmers Alliance Cos', value: 'Farmers Alliance Cos' },
    { id: 'Farmers and Mechanics', value: 'Farmers and Mechanics' },
    { id: 'Farmers Fire Insurance Co.', value: 'Farmers Fire Insurance Co.' },
    { id: 'Farmers Ins Co. of Flemington', value: 'Farmers Ins Co. of Flemington' },
    { id: 'Farmers Mutl Fire Ins Co Salem', value: 'Farmers Mutl Fire Ins Co Salem' },
    { id: 'Farmers Mutl Fire Ins Co. (OK)', value: 'Farmers Mutl Fire Ins Co. (OK)' },
    { id: 'Farmers Mutl Fire Marble PA', value: 'Farmers Mutl Fire Marble PA' },
    { id: 'Farmers Mutl Fire McCandless', value: 'Farmers Mutl Fire McCandless' },
    { id: 'Farmers Mutl Ins Co NE', value: 'Farmers Mutl Ins Co NE' },
    { id: 'Farmers Mutual Hail', value: 'Farmers Mutual Hail' },
    { id: 'Farmers Mutual Ins Co. (KS)', value: 'Farmers Mutual Ins Co. (KS)' },
    { id: 'Farmers Mutual Ins Co. (WV)', value: 'Farmers Mutual Ins Co. (WV)' },
    { id: 'Farmers Mutual Ins Co. of MI', value: 'Farmers Mutual Ins Co. of MI' },
    { id: 'Farmers Mutual of Tennessee', value: 'Farmers Mutual of Tennessee' },
    { id: 'Farmers Texas County Mutual', value: 'Farmers Texas County Mutual' },
    { id: 'Farmers Union Insurance', value: 'Farmers Union Insurance' },
    { id: 'Farmers Union Mutl Ins Co (MT)', value: 'Farmers Union Mutl Ins Co (MT)' },
    { id: 'Farmington Mutual Insurance Co', value: 'Farmington Mutual Insurance Co' },
    { id: 'FBAlliance Insurance', value: 'FBAlliance Insurance' },
    { id: 'Federated Insurance', value: 'Federated Insurance' },
    { id: 'FEDNAT', value: 'FEDNAT' },
    { id: 'Finger Lakes F&C Co.', value: 'Finger Lakes F&C Co.' },
    { id: "Fireman's Fund Insurance Co.", value: "Fireman's Fund Insurance Co." },
    { id: 'First Acceptance', value: 'First Acceptance' },
    { id: 'First American', value: 'First American' },
    { id: 'First Colonial Insurance Co.', value: 'First Colonial Insurance Co.' },
    { id: 'First Floridian Auto & Home', value: 'First Floridian Auto & Home' },
    { id: 'First Insurance Co. of HI Ltd.', value: 'First Insurance Co. of HI Ltd.' },
    { id: 'First Mercury SNL Subgroup', value: 'First Mercury SNL Subgroup' },
    { id: 'First Mutual Insurance Co.', value: 'First Mutual Insurance Co.' },
    { id: 'First Net Insurance Co.', value: 'First Net Insurance Co.' },
    { id: 'First Protective Insurance Co.', value: 'First Protective Insurance Co.' },
    { id: 'Florida Family Insurance', value: 'Florida Family Insurance' },
    { id: 'Florida Farm Bureau', value: 'Florida Farm Bureau' },
    { id: 'Florida Peninsula Holdings LLC', value: 'Florida Peninsula Holdings LLC' },
    { id: 'Florida Specialty Insurance Co', value: 'Florida Specialty Insurance Co' },
    { id: 'FM Global', value: 'FM Global' },
    { id: 'Foremost', value: 'Foremost' },
    { id: 'Foremost County Mutual Ins Co.', value: 'Foremost County Mutual Ins Co.' },
    { id: 'Foremost Lloyds of Texas', value: 'Foremost Lloyds of Texas' },
    { id: 'Founders Insurance Co.', value: 'Founders Insurance Co.' },
    { id: 'Frankenmuth Insurance', value: 'Frankenmuth Insurance' },
    { id: 'Franklin Mutual', value: 'Franklin Mutual' },
    { id: 'Frederick Mutual Insurance Co.', value: 'Frederick Mutual Insurance Co.' },
    { id: 'Friends Cove Mutual Ins Co.', value: 'Friends Cove Mutual Ins Co.' },
    { id: 'Frontier - Mt Carroll Mutl Ins', value: 'Frontier - Mt Carroll Mutl Ins' },
    { id: 'Fulmont Mutual Insurance Co.', value: 'Fulmont Mutual Insurance Co.' },
    { id: 'GEICO', value: 'GEICO' },
    { id: 'Gem State Insurance Co.', value: 'Gem State Insurance Co.' },
    { id: 'General Electric Co.', value: 'General Electric Co.' },
    { id: 'General Insurance', value: 'General Insurance' },
    { id: 'General Star Companies', value: 'General Star Companies' },
    { id: 'Generali U.S. Branch', value: 'Generali U.S. Branch' },
    { id: 'Genesee Patrons Coop Ins Co.', value: 'Genesee Patrons Coop Ins Co.' },
    { id: 'Geneva Insurance Co.', value: 'Geneva Insurance Co.' },
    { id: 'Georgia Farm Bureau', value: 'Georgia Farm Bureau' },
    { id: 'GeoVera Holdings', value: 'GeoVera Holdings' },
    { id: 'Germania Insurance', value: 'Germania Insurance' },
    { id: 'Germantown Mutual Insurance Co', value: 'Germantown Mutual Insurance Co' },
    { id: 'Global Indemnity', value: 'Global Indemnity' },
    { id: 'GNW Holding Inc.', value: 'GNW Holding Inc.' },
    { id: 'GNY Insurance Companies', value: 'GNY Insurance Companies' },
    { id: 'GoAuto Insurance Co.', value: 'GoAuto Insurance Co.' },
    { id: 'Golden Bear Insurance Co.', value: 'Golden Bear Insurance Co.' },
    { id: 'Goodville & German Mutual Group', value: 'Goodville & German Mutual Group' },
    { id: 'Grange', value: 'Grange' },
    { id: 'Grange Insurance Assn.', value: 'Grange Insurance Assn.' },
    { id: 'Grange Mutual Fire Ins Co.', value: 'Grange Mutual Fire Ins Co.' },
    { id: 'Great American Insurance Co.', value: 'Great American Insurance Co.' },
    { id: 'Great Lakes Mutual Ins Co.', value: 'Great Lakes Mutual Ins Co.' },
    { id: 'Greenville Casualty Ins Co.', value: 'Greenville Casualty Ins Co.' },
    { id: 'Grinnell Mutual', value: 'Grinnell Mutual' },
    { id: 'Growers Automobile Ins Assn.', value: 'Growers Automobile Ins Assn.' },
    { id: 'GUARD Insurance Companies', value: 'GUARD Insurance Companies' },
    { id: 'GuideOne Insurance', value: 'GuideOne Insurance' },
    { id: 'Gulf States Ins Co. (LA)', value: 'Gulf States Ins Co. (LA)' },
    { id: 'Gulfstream P&C Insurance Co.', value: 'Gulfstream P&C Insurance Co.' },
    { id: 'Halifax Mutual Insurance Co.', value: 'Halifax Mutual Insurance Co.' },
    { id: 'Hallmark', value: 'Hallmark' },
    { id: 'Hamilton Mutual Insurance Co.', value: 'Hamilton Mutual Insurance Co.' },
    { id: 'Hanover Fire & Casualty Ins Co', value: 'Hanover Fire & Casualty Ins Co' },
    { id: 'Harbor Insurance Co.', value: 'Harbor Insurance Co.' },
    { id: 'Harford Mutual', value: 'Harford Mutual' },
    { id: 'Harleysville Insurance', value: 'Harleysville Insurance' },
    { id: 'Hartford', value: 'Hartford' },
    { id: 'Hastings Mutual Insurance Co.', value: 'Hastings Mutual Insurance Co.' },
    { id: 'HCI Group, Inc.', value: 'HCI Group, Inc.' },
    { id: 'Heritage Insurance', value: 'Heritage Insurance' },
    { id: 'Hochheim Prairie Insurance', value: 'Hochheim Prairie Insurance' },
    { id: 'Home State Insurance Group', value: 'Home State Insurance Group' },
    { id: 'Homeowners of America Ins Co.', value: 'Homeowners of America Ins Co.' },
    { id: 'Homesite', value: 'Homesite' },
    { id: 'Horace Mann', value: 'Horace Mann' },
    { id: 'Houston International Insurance', value: 'Houston International Insurance' },
    { id: 'Hyundai Marine & Fire Ins Co.', value: 'Hyundai Marine & Fire Ins Co.' },
    { id: 'IAT Insurance Grp Inc.', value: 'IAT Insurance Grp Inc.' },
    { id: 'ICW', value: 'ICW' },
    { id: 'IMT', value: 'IMT' },
    { id: 'IN Farmers Mutl Ins Co', value: 'IN Farmers Mutl Ins Co' },
    { id: 'Independent Mutual Fire Ins Co', value: 'Independent Mutual Fire Ins Co' },
    { id: 'Indiana Farm Bureau', value: 'Indiana Farm Bureau' },
    { id: 'Infinity', value: 'Infinity' },
    { id: 'Inland Mutual Insurance Co.', value: 'Inland Mutual Insurance Co.' },
    { id: 'Integon', value: 'Integon' },
    { id: 'INTEGRAND Assurance Co.', value: 'INTEGRAND Assurance Co.' },
    { id: 'Ironshore Holdings (U.S.) Inc.', value: 'Ironshore Holdings (U.S.) Inc.' },
    { id: 'Island Home Insurance Co.', value: 'Island Home Insurance Co.' },
    { id: 'Island Insurance Companies', value: 'Island Insurance Companies' },
    { id: 'Jefferson Insurance Co.', value: 'Jefferson Insurance Co.' },
    { id: 'Juniata Mutual Insurance Co.', value: 'Juniata Mutual Insurance Co.' },
    { id: 'Kansas Mutual Insurance Co.', value: 'Kansas Mutual Insurance Co.' },
    { id: 'Kemper Direct', value: 'Kemper Direct' },
    { id: 'Kemper Insurance', value: 'Kemper Insurance' },
    { id: 'Kemper Specialty Insurance', value: 'Kemper Specialty Insurance' },
    { id: 'Kensington Insurance Co.', value: 'Kensington Insurance Co.' },
    { id: 'Kentucky Farm Bureau', value: 'Kentucky Farm Bureau' },
    { id: 'Kentucky Growers Insurance Co.', value: 'Kentucky Growers Insurance Co.' },
    { id: 'Kentucky National Insurance Co', value: 'Kentucky National Insurance Co' },
    { id: 'Key Insurance Co.', value: 'Key Insurance Co.' },
    { id: 'Kingstone Insurance Co.', value: 'Kingstone Insurance Co.' },
    { id: 'Kinsale Insurance Co.', value: 'Kinsale Insurance Co.' },
    { id: 'Knight Insurance', value: 'Knight Insurance' },
    { id: 'LA Citizens Ppty Ins Corp.', value: 'LA Citizens Ppty Ins Corp.' },
    { id: 'LA Farm Bureau Mutual Ins Co.', value: 'LA Farm Bureau Mutual Ins Co.' },
    { id: 'Leatherstocking Coop Ins Co.', value: 'Leatherstocking Coop Ins Co.' },
    { id: 'Lemonade Insurance Co.', value: 'Lemonade Insurance Co.' },
    { id: 'Liberty County Mutual', value: 'Liberty County Mutual' },
    { id: 'Liberty Mutual', value: 'Liberty Mutual' },
    { id: 'Lighthouse Property Ins Corp.', value: 'Lighthouse Property Ins Corp.' },
    { id: 'Lititz Mutual', value: 'Lititz Mutual' },
    { id: 'Little Black Mutual Ins Co.', value: 'Little Black Mutual Ins Co.' },
    { id: 'Lockhart Companies Inc.', value: 'Lockhart Companies Inc.' },
    { id: 'Loudoun Mutual Insurance Co.', value: 'Loudoun Mutual Insurance Co.' },
    { id: 'Loya Insurance', value: 'Loya Insurance' },
    { id: 'Lutheran Mutual Fire Ins Co.', value: 'Lutheran Mutual Fire Ins Co.' },
    { id: 'Madison Mutual Ins Co. (IL)', value: 'Madison Mutual Ins Co. (IL)' },
    { id: 'Madison Mutual Ins Co. (NY)', value: 'Madison Mutual Ins Co. (NY)' },
    { id: 'Maidstone Insurance Co.', value: 'Maidstone Insurance Co.' },
    { id: 'Maison Insurance Co.', value: 'Maison Insurance Co.' },
    { id: 'MAPFRE', value: 'MAPFRE' },
    { id: 'Mapfre Praico Corp.', value: 'Mapfre Praico Corp.' },
    { id: 'Maple Valley Mutual Ins Co.', value: 'Maple Valley Mutual Ins Co.' },
    { id: 'Markel American Insurance Co.', value: 'Markel American Insurance Co.' },
    { id: 'Markel Insurance Co.', value: 'Markel Insurance Co.' },
    { id: 'Maryland Automobile Ins Fund', value: 'Maryland Automobile Ins Fund' },
    { id: 'Marysville Mutual Insurance Co', value: 'Marysville Mutual Insurance Co' },
    { id: 'McMillan-Warner Mutual Ins Co.', value: 'McMillan-Warner Mutual Ins Co.' },
    { id: 'Mennonite Mutual Insurance Co.', value: 'Mennonite Mutual Insurance Co.' },
    { id: 'Merchants Insurance', value: 'Merchants Insurance' },
    { id: 'Mercury', value: 'Mercury' },
    { id: 'Metlife', value: 'Metlife' },
    { id: 'Metromile Insurance Co.', value: 'Metromile Insurance Co.' },
    { id: 'MFS Mutual Insurance Co.', value: 'MFS Mutual Insurance Co.' },
    { id: 'MGA Insurance Co.', value: 'MGA Insurance Co.' },
    { id: 'MI Automobile Ins Placement', value: 'MI Automobile Ins Placement' },
    { id: 'MI Basic Property Ins Assn.', value: 'MI Basic Property Ins Assn.' },
    { id: 'Michigan Farm Bureau', value: 'Michigan Farm Bureau' },
    { id: 'Michigan Insurance Co.', value: 'Michigan Insurance Co.' },
    { id: 'Mid-Hudson Co-Operative Ins Co', value: 'Mid-Hudson Co-Operative Ins Co' },
    { id: 'Midrox Insurance Co.', value: 'Midrox Insurance Co.' },
    { id: 'Midstate Mutual Insurance Co.', value: 'Midstate Mutual Insurance Co.' },
    { id: 'Midwest Family', value: 'Midwest Family' },
    { id: 'Millville Mutual', value: 'Millville Mutual' },
    { id: 'Missouri Farm Bureau Insurance', value: 'Missouri Farm Bureau Insurance' },
    { id: 'Missouri Valley Mutual Ins Co.', value: 'Missouri Valley Mutual Ins Co.' },
    { id: 'MMG Insurance Co.', value: 'MMG Insurance Co.' },
    { id: 'Modern USA Insurance Co.', value: 'Modern USA Insurance Co.' },
    { id: 'Montgomery Mutual Insurance Co', value: 'Montgomery Mutual Insurance Co' },
    { id: 'Montour Mutual Insurance Co.', value: 'Montour Mutual Insurance Co.' },
    { id: 'Motorists Insurance Group', value: 'Motorists Insurance Group' },
    { id: 'Motors Insurance Corporation', value: 'Motors Insurance Corporation' },
    { id: 'MS Farm Bureau Casualty Ins Co', value: 'MS Farm Bureau Casualty Ins Co' },
    { id: 'Mt. Morris Mutual Insurance Co', value: 'Mt. Morris Mutual Insurance Co' },
    { id: 'Mtn W Farm Bureau Mutl Ins Co.', value: 'Mtn W Farm Bureau Mutl Ins Co.' },
    { id: 'Multinational Insurance Co.', value: 'Multinational Insurance Co.' },
    { id: 'Munich Re America Corp.', value: 'Munich Re America Corp.' },
    { id: 'Municipal Mutual Ins Co. of WV', value: 'Municipal Mutual Ins Co. of WV' },
    { id: 'Mutual Benefit', value: 'Mutual Benefit' },
    { id: 'Mutual of Enumclaw', value: 'Mutual of Enumclaw' },
    { id: 'Mutual of Wausau', value: 'Mutual of Wausau' },
    { id: 'Mutual Savings Fire Ins Co.', value: 'Mutual Savings Fire Ins Co.' },
    { id: 'MutualAid eXchange', value: 'MutualAid eXchange' },
    { id: 'National Direct Insurance Co.', value: 'National Direct Insurance Co.' },
    { id: 'National General', value: 'National General' },
    { id: 'National Heritage Insurance Co', value: 'National Heritage Insurance Co' },
    { id: 'National Indemnity', value: 'National Indemnity' },
    { id: 'National Interstate Corp.', value: 'National Interstate Corp.' },
    { id: 'National Lloyds Corporation', value: 'National Lloyds Corporation' },
    { id: 'National Security Group Inc.', value: 'National Security Group Inc.' },
    { id: 'National Unity Insurance Co.', value: 'National Unity Insurance Co.' },
    { id: 'Nations Insurance Co.', value: 'Nations Insurance Co.' },
    { id: 'Nationwide', value: 'Nationwide' },
    { id: 'Nationwide E&S/Specialty', value: 'Nationwide E&S/Specialty' },
    { id: 'Nationwide Insurance Co. of FL', value: 'Nationwide Insurance Co. of FL' },
    { id: 'Nazareth Mutual Insurance Co.', value: 'Nazareth Mutual Insurance Co.' },
    { id: 'NBIC Holdings Inc.', value: 'NBIC Holdings Inc.' },
    { id: 'NC Grange Mutual Ins Co.', value: 'NC Grange Mutual Ins Co.' },
    { id: 'New London County Mutl Ins Co.', value: 'New London County Mutl Ins Co.' },
    { id: 'NGM Insurance Co.', value: 'NGM Insurance Co.' },
    { id: 'NJM', value: 'NJM' },
    { id: 'Nodak Mutual Group Inc.', value: 'Nodak Mutual Group Inc.' },
    { id: 'Norfolk & Dedham Group', value: 'Norfolk & Dedham Group' },
    { id: 'North Carolina Farm Bureau', value: 'North Carolina Farm Bureau' },
    { id: 'North Country Insurance Co.', value: 'North Country Insurance Co.' },
    { id: 'North Light Specialty Ins Co.', value: 'North Light Specialty Ins Co.' },
    { id: 'North Star Mutl Ins Co', value: 'North Star Mutl Ins Co' },
    { id: 'Northern Mutual Insurance Co.', value: 'Northern Mutual Insurance Co.' },
    { id: 'Northern Neck Insurance Co.', value: 'Northern Neck Insurance Co.' },
    { id: 'Northwest G.F. Mutual Ins Co.', value: 'Northwest G.F. Mutual Ins Co.' },
    { id: 'NW Farmers Mutual Insurance Co', value: 'NW Farmers Mutual Insurance Co' },
    { id: 'NYCM Insurance', value: 'NYCM Insurance' },
    { id: 'Odyssey Re Holdings Corp.', value: 'Odyssey Re Holdings Corp.' },
    { id: 'Ohio FAIR Plan Undrwtg Assn.', value: 'Ohio FAIR Plan Undrwtg Assn.' },
    { id: 'Ohio Mutual Insurance Group', value: 'Ohio Mutual Insurance Group' },
    { id: 'Oklahoma Farm Bureau Insurance', value: 'Oklahoma Farm Bureau Insurance' },
    { id: 'Old American', value: 'Old American' },
    { id: 'Old Reliable Casualty Co.', value: 'Old Reliable Casualty Co.' },
    { id: 'Old Repub General Ins Grp Inc.', value: 'Old Repub General Ins Grp Inc.' },
    { id: 'Olympus Insurance Co.', value: 'Olympus Insurance Co.' },
    { id: 'One Alliance Insurance Corp.', value: 'One Alliance Insurance Corp.' },
    { id: 'Ontario Insurance Co.', value: 'Ontario Insurance Co.' },
    { id: 'Oregon Mutual', value: 'Oregon Mutual' },
    { id: 'Oswego County Mutual Ins Co.', value: 'Oswego County Mutual Ins Co.' },
    { id: 'Otsego Cnty Patrons Co-Op Fire', value: 'Otsego Cnty Patrons Co-Op Fire' },
    { id: 'Otsego Mutual Fire Ins Co.', value: 'Otsego Mutual Fire Ins Co.' },
    { id: 'Pacific Indemnity Insurance Co', value: 'Pacific Indemnity Insurance Co' },
    { id: 'Pacific Pioneer Insurance Co.', value: 'Pacific Pioneer Insurance Co.' },
    { id: 'Pacific Specialty Insurance Company', value: 'Pacific Specialty Insurance Company' },
    { id: 'Palmetto Casualty Insurance Co', value: 'Palmetto Casualty Insurance Co' },
    { id: 'Palomar Spclty Ins Co', value: 'Palomar Spclty Ins Co' },
    { id: 'Panhandle Farmers Mutl Ins Co', value: 'Panhandle Farmers Mutl Ins Co' },
    { id: 'Paramount Insurance Co.', value: 'Paramount Insurance Co.' },
    { id: 'PartnerRe', value: 'PartnerRe' },
    { id: 'Patrons Co-op Fire Ins Co.', value: 'Patrons Co-op Fire Ins Co.' },
    { id: 'Patrons Mutual Fire Ins Co.', value: 'Patrons Mutual Fire Ins Co.' },
    { id: 'Peachtree Casualty Ins Co.', value: 'Peachtree Casualty Ins Co.' },
    { id: 'Pekin Insurance', value: 'Pekin Insurance' },
    { id: 'PEMCO Mutual Insurance Co.', value: 'PEMCO Mutual Insurance Co.' },
    { id: 'Peninsula Insurance Co.', value: 'Peninsula Insurance Co.' },
    { id: 'Penn National Insurance', value: 'Penn National Insurance' },
    { id: 'Penn-America Group Inc.', value: 'Penn-America Group Inc.' },
    { id: 'Pennsylvania Lumbermens Mutual', value: 'Pennsylvania Lumbermens Mutual' },
    { id: "People's Trust Insurance Co.", value: "People's Trust Insurance Co." },
    { id: 'Pharmacists Mutual', value: 'Pharmacists Mutual' },
    { id: 'Philadelphia Contributionship', value: 'Philadelphia Contributionship' },
    { id: 'Philadelphia Insurance Companies', value: 'Philadelphia Insurance Companies' },
    { id: 'Piedmont Mutual Insurance Co.', value: 'Piedmont Mutual Insurance Co.' },
    { id: 'Pioneer State Mutual Ins Co.', value: 'Pioneer State Mutual Ins Co.' },
    { id: 'Plymouth Rock', value: 'Plymouth Rock' },
    { id: 'Plymouth Rock Assurance', value: 'Plymouth Rock Assurance' },
    { id: 'Praetorian Insurance Co.', value: 'Praetorian Insurance Co.' },
    { id: 'Preferred Auto Insurance Co.', value: 'Preferred Auto Insurance Co.' },
    { id: 'Preferred Mutual Insurance Co.', value: 'Preferred Mutual Insurance Co.' },
    { id: 'Premier Holdings LLC', value: 'Premier Holdings LLC' },
    { id: 'Premier Insurance Co. of MA', value: 'Premier Insurance Co. of MA' },
    { id: 'Prepared Insurance Co.', value: 'Prepared Insurance Co.' },
    { id: 'Prime Insurance', value: 'Prime Insurance' },
    { id: "Producer's National Corp.", value: "Producer's National Corp." },
    { id: 'Progressive', value: 'Progressive' },
    { id: 'Protective Insurance Corp.', value: 'Protective Insurance Corp.' },
    { id: 'Providence Mutual Fire Ins Co.', value: 'Providence Mutual Fire Ins Co.' },
    { id: 'PURE', value: 'PURE' },
    { id: 'QBE Regional Cos. (N.A.)', value: 'QBE Regional Cos. (N.A.)' },
    { id: 'QBE Reinsurance Corporation', value: 'QBE Reinsurance Corporation' },
    { id: 'QBE Seguros', value: 'QBE Seguros' },
    { id: 'Qualitas Insurance Co.', value: 'Qualitas Insurance Co.' },
    { id: 'Quincy Mutual', value: 'Quincy Mutual' },
    { id: 'RAM Mutual Insurance Co.', value: 'RAM Mutual Insurance Co.' },
    { id: 'Real Legacy Assurance Co.', value: 'Real Legacy Assurance Co.' },
    { id: 'Reamstown Mutual Insurance Co.', value: 'Reamstown Mutual Insurance Co.' },
    { id: 'Redpoint County Mutual Ins Co.', value: 'Redpoint County Mutual Ins Co.' },
    { id: 'Repub Fire & Casualty Ins Co.', value: 'Repub Fire & Casualty Ins Co.' },
    { id: 'Repub Lloyds', value: 'Repub Lloyds' },
    { id: 'Republic Underwriters Ins Co.', value: 'Republic Underwriters Ins Co.' },
    { id: 'Republic-Vanguard Insurance Co', value: 'Republic-Vanguard Insurance Co' },
    { id: 'Responsive Auto Insurance Co.', value: 'Responsive Auto Insurance Co.' },
    { id: 'RFH Special Purpose I LLC', value: 'RFH Special Purpose I LLC' },
    { id: 'Rider Insurance Co.', value: 'Rider Insurance Co.' },
    { id: 'RLI', value: 'RLI' },
    { id: 'Rockford Mutual Insurance Co.', value: 'Rockford Mutual Insurance Co.' },
    { id: 'Rockhill Insurance', value: 'Rockhill Insurance' },
    { id: 'Rockingham Insurance', value: 'Rockingham Insurance' },
    { id: 'Root Insurance Co.', value: 'Root Insurance Co.' },
    { id: 'Rural Mutual Insurance Co.', value: 'Rural Mutual Insurance Co.' },
    { id: 'RVOS Insurance', value: 'RVOS Insurance' },
    { id: 'Safe Auto Insurance Co.', value: 'Safe Auto Insurance Co.' },
    { id: 'Safe Harbor Insurance Co.', value: 'Safe Harbor Insurance Co.' },
    { id: 'Safe Insurance Co.', value: 'Safe Insurance Co.' },
    { id: 'Safepoint Insurance Co.', value: 'Safepoint Insurance Co.' },
    { id: 'Safety Insurance', value: 'Safety Insurance' },
    { id: 'Safeway Insurance', value: 'Safeway Insurance' },
    { id: 'Saucon Insurance Co.', value: 'Saucon Insurance Co.' },
    { id: 'Sauquoit Valley Insurance Co.', value: 'Sauquoit Valley Insurance Co.' },
    { id: 'Sawgrass Mutual Insurance Co.', value: 'Sawgrass Mutual Insurance Co.' },
    { id: 'SC Farm Bureau Insurance Co.', value: 'SC Farm Bureau Insurance Co.' },
    { id: 'SC Farm Bureau Mutual Ins Co.', value: 'SC Farm Bureau Mutual Ins Co.' },
    { id: 'SCOR', value: 'SCOR' },
    { id: 'SECURA Insurance Companies', value: 'SECURA Insurance Companies' },
    { id: 'Security First Insurance Co.', value: 'Security First Insurance Co.' },
    { id: 'Security Mutual Insurance Co.', value: 'Security Mutual Insurance Co.' },
    { id: 'Selective', value: 'Selective' },
    { id: 'Sentry', value: 'Sentry' },
    { id: 'Service Insurance Co (FL)', value: 'Service Insurance Co (FL)' },
    { id: 'Shelter', value: 'Shelter' },
    { id: 'Sirius', value: 'Sirius' },
    { id: 'Sompo', value: 'Sompo' },
    { id: 'Southern County Mutual Ins Co.', value: 'Southern County Mutual Ins Co.' },
    { id: 'Southern Fidelity Insurance Co', value: 'Southern Fidelity Insurance Co' },
    { id: 'Southern Fidelity P&C Inc.', value: 'Southern Fidelity P&C Inc.' },
    { id: 'Southern General Insurance Co.', value: 'Southern General Insurance Co.' },
    { id: 'Southern Insurance Co.', value: 'Southern Insurance Co.' },
    { id: 'Southern Oak Insurance Co.', value: 'Southern Oak Insurance Co.' },
    { id: 'Southern Pioneer P&C Ins Co.', value: 'Southern Pioneer P&C Ins Co.' },
    { id: 'Southern Trust Insurance Co.', value: 'Southern Trust Insurance Co.' },
    { id: 'Southern Underwriters Ins Co.', value: 'Southern Underwriters Ins Co.' },
    { id: 'Southern Vanguard Insurance Co', value: 'Southern Vanguard Insurance Co' },
    { id: 'Southwest General Insurance Co', value: 'Southwest General Insurance Co' },
    { id: 'Spartan Insurance Co.', value: 'Spartan Insurance Co.' },
    { id: 'Spinnaker Insurance Co.', value: 'Spinnaker Insurance Co.' },
    { id: 'St. Johns Insurance Co.', value: 'St. Johns Insurance Co.' },
    { id: 'St. Paul Fire & Marine Ins Co.', value: 'St. Paul Fire & Marine Ins Co.' },
    { id: 'Standard Casualty Co.', value: 'Standard Casualty Co.' },
    { id: 'Star & Shield Ins Exchange', value: 'Star & Shield Ins Exchange' },
    { id: 'Star Casualty Insurance Co.', value: 'Star Casualty Insurance Co.' },
    { id: 'STARR Cos.', value: 'STARR Cos.' },
    { id: 'StarStone Ins Bermuda Ltd.', value: 'StarStone Ins Bermuda Ltd.' },
    { id: 'State Auto', value: 'State Auto' },
    { id: 'State Farm', value: 'State Farm' },
    { id: 'State National Companies Inc.', value: 'State National Companies Inc.' },
    { id: 'Sterling Casualty Insurance Co', value: 'Sterling Casualty Insurance Co' },
    { id: 'Sterling Insurance Co.', value: 'Sterling Insurance Co.' },
    { id: 'Sthrn Farm Bureau Cas Ins Co', value: 'Sthrn Farm Bureau Cas Ins Co' },
    { id: 'Stillwater Insurance Co.', value: 'Stillwater Insurance Co.' },
    { id: 'Sutter Insurance Co.', value: 'Sutter Insurance Co.' },
    { id: 'Tennessee Farmers Insurance', value: 'Tennessee Farmers Insurance' },
    { id: 'Texas FAIR Plan Association', value: 'Texas FAIR Plan Association' },
    { id: 'Texas Farm Bureau', value: 'Texas Farm Bureau' },
    { id: 'The Doctors Co.', value: 'The Doctors Co.' },
    { id: 'The Hanover', value: 'The Hanover' },
    { id: 'Tiptree Inc.', value: 'Tiptree Inc.' },
    { id: 'Titan Indemnity Co.', value: 'Titan Indemnity Co.' },
    { id: 'Tokio Marine & Nichido Fire', value: 'Tokio Marine & Nichido Fire' },
    { id: 'Topa Insurance', value: 'Topa Insurance' },
    { id: 'Tower Hill', value: 'Tower Hill' },
    { id: 'Traders Insurance Co.', value: 'Traders Insurance Co.' },
    { id: 'Travelers', value: 'Travelers' },
    { id: 'Triple-S Propiedad Inc.', value: 'Triple-S Propiedad Inc.' },
    { id: 'Tri-State Consumer Ins Co.', value: 'Tri-State Consumer Ins Co.' },
    { id: 'Triton Insurance Co.', value: 'Triton Insurance Co.' },
    { id: 'Tuscarora Wayne Mutual Grp Inc', value: 'Tuscarora Wayne Mutual Grp Inc' },
    { id: 'U.S. Investment Corp.', value: 'U.S. Investment Corp.' },
    { id: "Underwriters at Lloyd's (VI)", value: "Underwriters at Lloyd's (VI)" },
    { id: 'Union Ins Co. of Providence', value: 'Union Ins Co. of Providence' },
    { id: 'Union Mutual', value: 'Union Mutual' },
    { id: 'Union Mutual Insurance Co.', value: 'Union Mutual Insurance Co.' },
    { id: 'United Casualty Ins Co. of Am', value: 'United Casualty Ins Co. of Am' },
    { id: 'United Equitable Group Ltd.', value: 'United Equitable Group Ltd.' },
    { id: 'United Fire Group Inc.', value: 'United Fire Group Inc.' },
    { id: 'United Frontier Mutual Ins Co.', value: 'United Frontier Mutual Ins Co.' },
    { id: 'United Heritage Insurance', value: 'United Heritage Insurance' },
    { id: 'United Home Insurance Co.', value: 'United Home Insurance Co.' },
    { id: 'United Insurance Co.', value: 'United Insurance Co.' },
    { id: 'United National Insurance Co.', value: 'United National Insurance Co.' },
    { id: 'Univ Ins Co. of North America', value: 'Univ Ins Co. of North America' },
    { id: 'Universal Insurance', value: 'Universal Insurance' },
    { id: 'Universal Insurance Co (PR)', value: 'Universal Insurance Co (PR)' },
    { id: 'Universal North America Ins Co', value: 'Universal North America Ins Co' },
    { id: 'Untd Automobile Ins Co', value: 'Untd Automobile Ins Co' },
    { id: 'UPC Insurance', value: 'UPC Insurance' },
    { id: 'Upland Mutual Insurance Inc.', value: 'Upland Mutual Insurance Inc.' },
    { id: 'US Coastal', value: 'US Coastal' },
    { id: 'US Lloyds Insurance Co.', value: 'US Lloyds Insurance Co.' },
    { id: 'USA Insurance Co.', value: 'USA Insurance Co.' },
    { id: 'USA Underwriters', value: 'USA Underwriters' },
    { id: 'USAA', value: 'USAA' },
    { id: 'Utica First Insurance Co. Inc', value: 'Utica First Insurance Co. Inc' },
    { id: 'Utica Mutual Insurance Co.', value: 'Utica Mutual Insurance Co.' },
    { id: 'VA Farm Bureau Federation', value: 'VA Farm Bureau Federation' },
    { id: 'Vault Reciprocal Exchange', value: 'Vault Reciprocal Exchange' },
    { id: 'Vermont Mutual Insurance', value: 'Vermont Mutual Insurance' },
    { id: 'Victoria Insurance', value: 'Victoria Insurance' },
    { id: 'W L Dunn Group', value: 'W L Dunn Group' },
    { id: 'Wall Rose Mutual Insurance Co.', value: 'Wall Rose Mutual Insurance Co.' },
    { id: 'Warrior Invictus Holding Co.', value: 'Warrior Invictus Holding Co.' },
    { id: 'Washington County Coop Ins Co', value: 'Washington County Coop Ins Co' },
    { id: 'Wawanesa General Insurance Co.', value: 'Wawanesa General Insurance Co.' },
    { id: 'Wayne Cooperative Insurance Co', value: 'Wayne Cooperative Insurance Co' },
    { id: 'Wayne Mutual Insurance Co.', value: 'Wayne Mutual Insurance Co.' },
    { id: 'WEA P&C Insurance Co.', value: 'WEA P&C Insurance Co.' },
    { id: 'West Bend Mutual Insurance Co.', value: 'West Bend Mutual Insurance Co.' },
    { id: 'West Branch Mutl Insurance Co.', value: 'West Branch Mutl Insurance Co.' },
    { id: 'West Virginia Insurance Co.', value: 'West Virginia Insurance Co.' },
    { id: 'West Virginia Natl Auto Ins Co', value: 'West Virginia Natl Auto Ins Co' },
    { id: 'Western General Insurance Co.', value: 'Western General Insurance Co.' },
    { id: 'Western Mutual Insurance', value: 'Western Mutual Insurance' },
    { id: 'Western National Insurance', value: 'Western National Insurance' },
    { id: 'Western Reserve Group', value: 'Western Reserve Group' },
    { id: 'Western Select Insurance Co.', value: 'Western Select Insurance Co.' },
    { id: 'Western World', value: 'Western World' },
    { id: 'Westfield Insurance', value: 'Westfield Insurance' },
    { id: 'WI Mutl Ins Co', value: 'WI Mutl Ins Co' },
    { id: 'Wilmington Insurance Co.', value: 'Wilmington Insurance Co.' },
    { id: 'Windhaven Insurance Co.', value: 'Windhaven Insurance Co.' },
    { id: 'Windhaven National Ins Co.', value: 'Windhaven National Ins Co.' },
    { id: 'Windsor Mount Joy Mutual Insurance Co.', value: 'Windsor Mount Joy Mutual Insurance Co.' },
    { id: 'Wisconsin Reinsurance Corp.', value: 'Wisconsin Reinsurance Corp.' },
    { id: 'Wolverine Mutual Insurance Co.', value: 'Wolverine Mutual Insurance Co.' },
    { id: 'Woodlands Insurance Co.', value: 'Woodlands Insurance Co.' },
    { id: 'XL Group', value: 'XL Group' },
    { id: 'Yosemite Insurance Co.', value: 'Yosemite Insurance Co.' },
    { id: 'Zurich American Insurance Co.', value: 'Zurich American Insurance Co.' }
];
export default insuranceProviderList;
