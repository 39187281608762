import largeAgencyCodes from './largeAgencyCodes';
const affinityGroups = {
    A360: {
        hash: 'fdsa360ee',
        homeSecurity: true,
        name: 'Alert 360',
        states: {
            AZ: 2,
            OH: 2,
            IL: 2,
            IN: 2,
            MD: 2,
            MO: 2,
            PA: 2,
            TX: 2
        }
    },
    A360E: {
        hash: 'fda360eeeeemp',
        homeSecurity: true,
        name: 'Alert 360',
        states: {
            AZ: 2,
            OH: 2,
            IL: 2,
            IN: 2,
            MD: 2,
            MO: 2,
            TX: 2
        }
    },
    AAA1: {
        hash: 'f98j1kljzc',
        name: 'American Automobile Association',
        agency: true,
        serviceAgreement: true,
        disableChat: true,
        informationId: 'AAA1'
    },
    AB1: {
        hash: 'fdsvu4e',
        homeSecurity: true,
        canBeRemoved: true,
        name: 'Abode Pro Plan',
        additional: 100,
        states: {
            AL: 2,
            AZ: 2,
            CO: 2,
            GA: 2,
            IL: 2,
            IN: 2,
            KS: 2,
            KY: 2,
            MD: 2,
            MI: 2,
            MO: 2,
            NE: 2,
            NJ: 2,
            NM: 2,
            NV: 2,
            NY: 2,
            OH: 2,
            OR: 2,
            OK: 2,
            PA: 2,
            RI: 2,
            SC: 2,
            TX: 2,
            UT: 2,
            VA: 2,
            WV: 2,
            WI: 2
        }
    },
    /** For existing Abode Pro Plan customers who shouldn't be charged for it */
    AB2: {
        hash: '4jhif9818v',
        homeSecurity: true,
        name: 'Abode Pro Plan (Existing Customers)',
        states: {
            AL: 2,
            AZ: 2,
            CO: 2,
            GA: 2,
            IL: 2,
            IN: 2,
            KS: 2,
            KY: 2,
            MD: 2,
            MI: 2,
            MO: 2,
            NE: 2,
            NJ: 2,
            NM: 2,
            NV: 2,
            NY: 2,
            OH: 2,
            OR: 2,
            OK: 2,
            PA: 2,
            RI: 2,
            SC: 2,
            TX: 2,
            VA: 2,
            WV: 2,
            WI: 2
        }
    },
    ABC1: {
        hash: 'fdsvabcu4e',
        name: 'Action Behavior Centers',
        states: {
            AZ: 2,
            CO: 2,
            IL: 2,
            IN: 2,
            MI: 2,
            MO: 2,
            NE: 2,
            OH: 2,
            OK: 2,
            PA: 2,
            TX: 2
        }
    },
    AC1: {
        hash: 'fdscccce',
        name: 'Acorns',
        states: {
            AZ: 0,
            OH: 0,
            IL: 0,
            IN: 0,
            MO: 0,
            TX: 0
        }
    },
    AD1: {
        hash: 'addhv8',
        featuredProvider: true,
        homeSecurity: true,
        name: 'ADT',
        signUpThroughBranch: true,
        states: {
            AL: 2,
            AZ: 2,
            CO: 2,
            GA: 2,
            IL: 2,
            IN: 2,
            KS: 2,
            KY: 2,
            MD: 2,
            MI: 2,
            MO: 2,
            NE: 2,
            NJ: 2,
            NV: 2,
            NY: 2,
            OH: 2,
            OR: 2,
            OK: 2,
            RI: 2,
            SC: 2,
            TX: 2,
            UT: 2,
            VA: 2,
            WI: 2,
            WV: 2
        }
    },
    ADE: {
        hash: 'addhv8E',
        featuredProvider: true,
        homeSecurity: true,
        name: 'ADT Employee',
        states: {
            AL: 2,
            AZ: 2,
            CO: 2,
            GA: 2,
            IL: 2,
            IN: 2,
            KS: 2,
            KY: 2,
            MD: 2,
            MI: 2,
            MO: 2,
            NE: 2,
            NJ: 2,
            NV: 2,
            NY: 2,
            OH: 2,
            OR: 2,
            OK: 2,
            RI: 2,
            SC: 2,
            TX: 2,
            UT: 2,
            VA: 2,
            WI: 2,
            WV: 2
        }
    },
    AGHRO: {
        hash: 'baager',
        name: 'Agent Hero',
        agency: true,
        serviceAgreement: true,
        disableChat: true
    },
    aghro107: {
        hash: 'aghro107hash',
        name: 'Insuragy, LLC'
    },
    AGM1: {
        name: 'Affinity Group Mortgage',
        hash: '7dhdf8vc'
    },
    AIS: {
        hash: 'AIShash',
        name: 'American Insurance Strategies'
    },
    AIS1: {
        hash: 'AIS1hash',
        agency: true,
        serviceAgreement: true,
        name: 'American Insurance Strategies'
    },
    ALNK1: {
        hash: 'alnk1hash',
        agency: true,
        serviceAgreement: true,
        name: 'ALINK Insurance Service'
    },
    ANSFIN1: {
        name: 'Answer Financial',
        hash: '0jkl298lsdf',
        agency: true,
        serviceAgreement: true,
        disableChat: true
    },
    AP1: {
        hash: '7dhv8',
        name: 'Alert Protective',
        states: {
            IL: 2
        }
    },
    APE: {
        hash: '7dhv8E',
        name: 'Alert Protective',
        states: {
            IL: 2
        }
    },
    APHW: {
        hash: 'ahfgdspew',
        name: "America's Preferred Home Warranty",
        informationId: 'AHPW',
        states: {
            AZ: 1,
            CO: 1,
            IL: 1,
            IN: 1,
            MO: 1,
            NE: 1,
            OH: 1,
            OK: 1,
            TX: 1,
            UT: 1,
            WI: 1
        }
    },
    APRG1: {
        name: 'The Savings Group (AutoPay/RateGenius)',
        hash: 'aprg1hash'
    },
    ARCH1: {
        hash: 'arch1hash',
        name: 'Archer Insurance Solutions',
        agency: true
    },
    ASKOTTO: {
        hash: '7ask88otto',
        name: 'AskOtto',
        informationId: 'ASKOTTO'
    },
    ASQ1: {
        hash: 'asq1hash',
        name: 'ASQ Technologies LLC',
        agency: true
    },
    ASQ2: {
        hash: 'asq2hash',
        name: 'Grace Affordable Insurance Agency, Inc.',
        agency: true
    },
    AST1: {
        hash: 'ast1hash',
        name: 'Asterion Insurance Services LLC',
        agency: true
    },
    AURE1: {
        hash: 'ytQym4cF4rGAGhy6',
        name: 'Benchmark Insurance Group'
    },
    AZL1: {
        hash: 'dclesdfisg',
        name: 'Arizona Lending Group',
        states: {
            AZ: 2
        }
    },
    BA1: {
        hash: 'babetter',
        name: 'Betters Agency',
        agency: true,
        serviceAgreement: true,
        disableChat: true
    },
    BAD: {
        hash: 'baddab',
        name: 'BAD Agency from QTB API',
        agency: true
    },
    BAX1: {
        hash: 'bax1hash',
        name: 'Baxter Insurance Agency',
        agency: true
    },
    BCA: {
        name: 'Bob Caldwell Chrysler Jeep',
        hash: 'bdcz4xvy'
    },
    BDDG1: {
        hash: 'bddg1hash',
        name: 'Bird Dog Insurance Services, LLC',
        agency: true
    },
    BH1: {
        name: 'Buckeye Honda',
        hash: 'gf5subh18g'
    },
    BIG1: {
        hash: 'fsdbuds87',
        name: 'Benchmark Insurance Group'
    },
    BLRDG1: {
        hash: 'blrdg1hash',
        name: 'Blue Ridge Insurance',
        agency: true
    },
    BN1: {
        name: 'Buckeye Nissan',
        hash: 'basj6kdvu'
    },
    BOBB: {
        name: 'Bobb Automotive',
        hash: 'lsdy76v5'
    },
    BRANS1: {
        hash: 'brans1hash',
        name: 'Branscomb and Associates, LLC',
        agency: true
    },
    BRIJ1: {
        hash: 'brij1hash',
        name: 'InsureUs',
        agency: true
    },
    BPRS1: {
        hash: 'bprs1hash',
        name: 'Bespros Insurance and Financial Services LLC',
        agency: true
    },
    BRPT1: {
        hash: 'brpt1hash',
        name: 'Bridgepoint Insurance Solutions',
        agency: true
    },
    BWB: {
        hash: 'babwer',
        name: 'Baker Welman Brown',
        agency: true,
        serviceAgreement: true,
        disableChat: true
    },
    CAF1: {
        name: 'CarsFast',
        hash: '12jlkjvasd3',
        informationId: 'CAF1'
    },
    CAPK: {
        name: 'Capital Kia',
        hash: 'gf5ckiad98g'
    },
    CATN1: {
        hash: 'catn1hash',
        name: 'Catanzaro Insurance',
        agency: true
    },
    CAV: {
        hash: 'nocavf1',
        agency: true,
        serviceAgreement: true,
        name: 'Cavender Insurance',
        informationId: 'CAV'
    },
    CC1: {
        hash: 'cochdcolisd',
        name: 'Coach Collins Insurance',
        agency: true,
        serviceAgreement: true,
        informationId: 'CC1'
    },
    CCM1: {
        hash: '9jlkj109puisd',
        name: 'Cherry Creek Mortgage'
    },
    CCT: {
        name: 'Columbus Car Trader',
        hash: 'gfcard98g'
    },
    CFG1: {
        hash: 'dfcfgad87',
        name: 'Crossfit Grandview'
    },
    CHIME1: {
        hash: 'chdimeow',
        name: 'Chime',
        states: {
            AZ: 2,
            CO: 2,
            IL: 2,
            IN: 2,
            MD: 2,
            MI: 2,
            MO: 2,
            NE: 2,
            OH: 2,
            OK: 2,
            TX: 2,
            UT: 2,
            WI: 2
        }
    },
    CI1: {
        hash: 'cidufds9',
        name: 'Cleveland Insurance Brokers',
        agency: true,
        serviceAgreement: true,
        informationId: 'CI1',
        disableChat: true
    },
    CI2: {
        hash: 'cidufds92',
        name: 'Cleveland Insurance Brokers',
        agency: true,
        serviceAgreement: true,
        informationId: 'CI1',
        disableChat: true
    },
    CI3: {
        hash: 'cidufds93',
        name: 'Cleveland Insurance Brokers',
        agency: true,
        serviceAgreement: true,
        informationId: 'CI1',
        disableChat: true
    },
    CI4: {
        hash: 'cidufds94',
        name: 'Cleveland Insurance Brokers',
        agency: true,
        serviceAgreement: true,
        informationId: 'CI1',
        disableChat: true
    },
    CI5: {
        hash: 'cidufds95',
        name: 'Cleveland Insurance Brokers',
        agency: true,
        serviceAgreement: true,
        informationId: 'CI1',
        disableChat: true
    },
    CINS1: {
        hash: 'cins1hash',
        agency: true,
        name: 'C1 Insurance Group'
    },
    CISS: {
        hash: 'nciss3f1',
        agency: true,
        serviceAgreement: true,
        name: 'Colorado Insurance',
        informationId: 'CISS'
    },
    CKIA: {
        name: 'Celebration Kia',
        hash: 'gf5ckcelg'
    },
    CLEND1: {
        hash: 'dclesdfisg',
        name: 'Coastal Lending Group',
        states: {
            MD: 2
        }
    },
    CMRG1: {
        hash: 'cmrg1hash',
        name: 'Camargo Insurance',
        agency: true
    },
    COVSAGE: {
        hash: 'cusage294tree',
        agency: true
    },
    CT1: {
        hash: 'cudngeu294tree',
        agency: true
    },
    COM1: {
        hash: '2xjv2m5u',
        name: 'Compare.com'
    },
    COV1: {
        hash: 'dfsjkwww3sfa7',
        name: 'Cover.com',
        agency: true
    },
    CPN: {
        name: 'Cedar Park Nissan',
        hash: 'gfcpncvtg'
    },
    CRLS1: {
        hash: 'crls1hash',
        name: 'CHARALES INSURANCE AGY INC',
        agency: true
    },
    CROL1: {
        hash: 'crol1hash',
        name: 'Carroll Agency Inc',
        agency: true
    },
    CVT1: {
        name: 'Covert CJDR',
        hash: 'gf5sucvtg'
    },
    DAA: {
        name: 'Don Ayres Acura',
        hash: 'sfd4145et'
    },
    DAH: {
        name: 'Don Ayres Honda',
        hash: '5sdf14gr'
    },
    DGC: {
        name: 'Dave Gill Chevrolet',
        hash: 'gfdgcd98g'
    },
    DG1: {
        hash: 'iewdgs532',
        agency: true,
        serviceAgreement: true,
        name: 'DG Agency',
        informationId: 'DG1',
        disableChat: true
    },
    DH1: {
        name: 'Dennis Hyundai East',
        hash: '87asd5fgh'
    },
    DMF: {
        name: 'Dick Masheter Ford',
        hash: 'gfddmf98g'
    },
    DP1: {
        hash: 'dfsdp1sfa7',
        name: 'Dealer Policy',
        agency: true
    },
    DT1: {
        hash: '2a48d',
        name: 'Driver Technologies',
        states: {
            AL: 2,
            AZ: 2,
            CO: 2,
            GA: 2,
            IL: 2,
            IN: 2,
            KS: 2,
            KY: 2,
            MD: 2,
            MI: 2,
            MO: 2,
            NE: 2,
            NJ: 2,
            NV: 2,
            NY: 2,
            OH: 2,
            OR: 2,
            OK: 2,
            RI: 2,
            SC: 2,
            TX: 2,
            UT: 2,
            VA: 2,
            WI: 2,
            WV: 2
        }
    },
    DTE: {
        hash: '2a48dE',
        name: 'Driver Technologies',
        states: {
            AL: 2,
            AZ: 2,
            CO: 2,
            GA: 2,
            IL: 2,
            IN: 2,
            KS: 2,
            KY: 2,
            MD: 2,
            MI: 2,
            MO: 2,
            NE: 2,
            NJ: 2,
            NV: 2,
            NY: 2,
            OH: 2,
            OR: 2,
            OK: 2,
            RI: 2,
            SC: 2,
            TX: 2,
            VA: 2,
            WI: 2,
            WV: 2
        }
    },
    DTM: {
        name: 'Drive Time',
        hash: '65fds16df4g'
    },
    DWJ: {
        name: 'Driving with Janae',
        hash: 'df4d562as'
    },
    EI1: {
        hash: 'eid7vn238fdas',
        name: 'Eades Insurance',
        agency: true,
        serviceAgreement: true,
        informationId: 'EI1',
        disableChat: true
    },
    EI2: {
        hash: 'eid7vn238fdas2',
        name: 'Eades Insurance',
        agency: true,
        serviceAgreement: true,
        informationId: 'EI1',
        disableChat: true
    },
    ELD1: {
        hash: 'dfkjvfusadeld',
        name: 'Elder Insurance',
        informationId: 'ELD1'
    },
    EM1: {
        hash: '4389dfsnj8d'
    },
    EPLR1: {
        hash: 'eplr1hash',
        agency: true,
        name: 'Eppler Insurance Group, LLC'
    },
    ERFY1: {
        hash: 'erfy1hash',
        name: 'Eversify Insurance',
        agency: true
    },
    EXM: {
        name: 'Exclusive Motorcars',
        hash: '8kjf75l798'
    },
    FAHW: {
        hash: 'ahfgdspew',
        name: 'First American Home Warranty',
        states: {
            AZ: 1,
            CO: 1,
            IL: 1,
            IN: 1,
            MD: 1,
            MO: 1,
            NE: 1,
            OH: 1,
            OK: 1,
            TX: 1,
            UT: 1,
            WI: 1
        }
    },
    FH1: {
        hash: 'h2O0W',
        name: 'Fischer Homes',
        states: {
            MD: 1,
            OH: 1,
            OK: 1
        }
    },
    FHE: {
        hash: 'h2O0WE',
        name: 'Fischer Homes',
        states: {
            IN: 1,
            OH: 1,
            OK: 1
        }
    },
    FLO1: {
        hash: 'BXrw5z',
        name: 'Floify'
    },
    FLO2: {
        hash: 'BXrw5z2',
        name: 'Floify',
        states: {
            CO: 2,
            IL: 2,
            TX: 2
        }
    },
    FLOE: {
        hash: 'BXrw5z3',
        name: 'Floify Employees',
        states: {
            CO: 2,
            IL: 2,
            TX: 2
        }
    },
    FMA1: {
        hash: 'fma74593d',
        name: 'First & Main',
        states: {
            IL: 2,
            OH: 2,
            MO: 2
        }
    },
    FOUND: {
        hash: 'fffound5',
        name: 'Found It',
        states: {
            AZ: 1,
            CO: 1,
            IL: 1,
            IN: 1,
            MI: 1,
            MO: 1,
            NE: 1,
            OH: 1,
            OK: 1,
            PA: 1,
            TX: 1,
            UT: 1,
            WI: 1
        }
    },
    FREF1: {
        hash: 'fref1hash',
        name: 'Firefly Agency LLC',
        agency: true
    },
    FW1: {
        hash: 'fopi1o23k',
        name: 'Fairway Independent Mortgage'
    },
    GABI1: {
        hash: 'gabigobble',
        name: 'Gabi',
        agency: true
    },
    GH1: {
        hash: 'sdafhg74',
        name: 'Goosehead',
        agency: true,
        serviceAgreement: true,
        ccEmail: 'mailbox@goosehead.com',
        informationId: 'GH1'
    },
    GH2: {
        hash: 'sdafhg742',
        name: 'Goosehead',
        agency: true,
        serviceAgreement: true,
        ccEmail: 'mailbox@goosehead.com',
        informationId: 'GH1'
    },
    GH3: {
        hash: 'sdafhg3742',
        name: 'Goosehead',
        agency: true,
        serviceAgreement: true,
        ccEmail: 'mailbox@goosehead.com',
        informationId: 'GH1'
    },
    GN1: {
        name: 'Georgesville Nissan',
        hash: 'axs1s5s5sxw2'
    },
    GRTRATE: {
        hash: '3kljv90adjc',
        agency: true
    },
    GRZO1: {
        hash: 'grzo1hash',
        name: 'Graziano Lorince Insurance, LLC',
        agency: true
    },
    HB1: {
        hash: 'hbudsfnsa7f65',
        name: 'Heartland Bank',
        states: {
            IN: 1,
            OH: 1,
            OK: 1
        }
    },
    HENNA: {
        name: 'Henna Chevrolet',
        hash: 'gfhenna18g'
    },
    HMPT1: {
        hash: 'hmpta7f65',
        name: 'Homepoint',
        states: {
            AL: 1,
            AZ: 1,
            CO: 1,
            GA: 1,
            IL: 1,
            IN: 1,
            KS: 1,
            KY: 1,
            MD: 1,
            MI: 1,
            MO: 1,
            NE: 1,
            NJ: 1,
            NV: 1,
            NY: 1,
            OH: 1,
            OR: 1,
            OK: 1,
            PA: 1,
            RI: 1,
            SC: 1,
            TX: 1,
            UT: 1,
            VA: 1,
            WI: 1,
            WV: 1
        }
    },
    HMPT2: {
        hash: 'hmptb319a',
        name: 'Homepoint API',
        states: {
            AL: 1,
            AZ: 1,
            CO: 1,
            GA: 1,
            IL: 1,
            IN: 1,
            KS: 1,
            KY: 1,
            MD: 1,
            MI: 1,
            MO: 1,
            NE: 1,
            NJ: 1,
            NV: 1,
            NY: 1,
            OH: 1,
            OR: 1,
            OK: 1,
            PA: 1,
            RI: 1,
            SC: 1,
            TX: 1,
            UT: 1,
            VA: 1,
            WI: 1,
            WV: 1
        }
    },
    HMPT3: {
        hash: 'hmpt3jzlk1z',
        name: 'Homepoint Inbound Transfer Call',
        states: {
            AL: 1,
            AZ: 1,
            CO: 1,
            GA: 1,
            IL: 1,
            IN: 1,
            KS: 1,
            KY: 1,
            MD: 1,
            MI: 1,
            MO: 1,
            NE: 1,
            NJ: 1,
            NV: 1,
            NY: 1,
            OH: 1,
            OR: 1,
            OK: 1,
            PA: 1,
            RI: 1,
            SC: 1,
            TX: 1,
            UT: 1,
            VA: 1,
            WI: 1,
            WV: 1
        }
    },
    HMPT4: {
        hash: 'hmpt3jzlk4z',
        name: 'Homepoint Origination',
        states: {
            AL: 1,
            AZ: 1,
            CO: 1,
            GA: 1,
            IL: 1,
            IN: 1,
            KS: 1,
            KY: 1,
            MD: 1,
            MI: 1,
            MO: 1,
            NE: 1,
            NJ: 1,
            NV: 1,
            NY: 1,
            OH: 1,
            OR: 1,
            OK: 1,
            PA: 1,
            RI: 1,
            SC: 1,
            TX: 1,
            UT: 1,
            VA: 1,
            WI: 1,
            WV: 1
        }
    },
    HMPTE: {
        hash: 'hmpte319a',
        name: 'Homepoint Employees',
        states: {
            AZ: 2,
            MI: 2,
            NE: 2,
            TX: 2
        }
    },
    HOME1: {
        hash: 'home1hash',
        name: 'Homeowners Insurance Group',
        agency: true
    },
    HOMER: {
        name: 'Homer Lending',
        hash: 'fjo39zlkjlz2'
    },
    HOP: {
        name: 'Hopper Motorplex',
        hash: 'gf5shopg'
    },
    HR1: {
        hash: 'NHMvhrrKMSmTL',
        name: 'HR Benefits'
    },
    HRZ: {
        hash: 'hrz533',
        agency: true,
        serviceAgreement: true,
        name: 'HRZ',
        disableChat: true
    },
    HTAP: {
        name: 'Hometap',
        hash: 'htpdfds5',
        states: {
            AZ: 1,
            IN: 1,
            MI: 1,
            NE: 1,
            TX: 1
        }
    },
    HUWL1: {
        hash: 'huwl1hash',
        name: 'Huwel Insurance Agency',
        agency: true
    },
    HVSU1: {
        hash: 'hvsu1hash',
        name: 'Havasu Insurance',
        agency: true
    },
    HWBC: {
        name: 'Hugh White Buick Chevy',
        hash: 'gf5chwbcd98g'
    },
    IAL1: {
        hash: 'ial1hash',
        agency: true,
        name: 'IAL Insurance'
    },
    IL1: {
        hash: 'NHMv4jkPqrKMSmTL',
        name: 'iLendingDIRECT',
        states: {
            AZ: 1,
            CO: 1,
            IL: 1,
            IN: 1,
            MI: 1,
            MO: 1,
            NE: 1,
            OH: 1,
            OK: 1,
            TX: 1,
            UT: 1,
            WI: 1
        }
    },
    IN1: {
        hash: 'ind79f',
        agency: true,
        serviceAgreement: true,
        name: 'Insurify',
        informationId: 'IN1'
    },
    INSMRT1: {
        hash: 'insmmmm',
        name: 'Insuremart, Inc.',
        agency: true,
        serviceAgreement: true,
        disableChat: true,
        informationId: 'INSMRT1'
    },
    INT: {
        hash: 'intd79f',
        agency: true,
        serviceAgreement: true,
        name: 'Integra Insurance Solutions'
    },
    INSP1: {
        hash: 'dfsjkga678',
        name: 'Inspectify'
    },
    INSTM1: {
        hash: 'instm1hash',
        agency: true,
        name: 'The Insurance Team'
    },
    IY1: {
        hash: 'iewmvds532',
        agency: true,
        serviceAgreement: true,
        name: 'Insuredly',
        informationId: 'IY1',
        disableChat: true
    },
    IY2: {
        hash: 'iewmvds533',
        agency: true,
        serviceAgreement: true,
        name: 'Insuredly',
        informationId: 'IY1',
        disableChat: true
    },
    IY3: {
        hash: 'iewmvds534',
        agency: true,
        serviceAgreement: true,
        name: 'Insuredly',
        informationId: 'IY1',
        disableChat: true
    },
    IY4: {
        hash: 'iewmvds5354',
        agency: true,
        serviceAgreement: true,
        name: 'Insuredly',
        informationId: 'IY1',
        disableChat: true
    },
    IY5: {
        hash: 'iewmvds53554',
        agency: true,
        serviceAgreement: true,
        name: 'Insuredly',
        informationId: 'IY1',
        disableChat: true
    },
    JC1: {
        hash: 'iewjjc53',
        agency: true,
        serviceAgreement: true,
        name: 'JC Smith Insurance',
        informationId: 'JC1'
    },
    JCBS1: {
        hash: 'jcbs1hash',
        name: 'Jacobs Insurance Solutions',
        agency: true
    },
    JD1: {
        hash: 'dfsjkvjd1',
        agency: true,
        serviceAgreement: true,
        name: 'Jared Reynolds Insurance',
        informationId: 'JD1',
        disableChat: true
    },
    JL1: {
        hash: 'iewjlllds53',
        name: 'Jennifer Lombardo',
        agency: true,
        serviceAgreement: true,
        informationId: 'JL1'
    },
    JLEE1: {
        hash: 'jlee1hash',
        agency: true,
        serviceAgreement: true,
        name: 'John Lee Insurance Agency LLC'
    },
    JR1: {
        hash: 'iewjrds53',
        name: 'Joseph W. Reeves Insurance',
        agency: true,
        serviceAgreement: true,
        informationId: 'JR1'
    },
    KG1: {
        hash: 'dfsakvs7fsdkg',
        name: 'Kangaroo',
        allowRestricted: {
            MI: true
        },
        homeSecurity: true,
        signUpThroughBranch: true
    },
    KG2: {
        hash: 'dfsakvs7fsdkg',
        name: 'Kangaroo',
        allowRestricted: {
            MI: true
        },
        homeSecurity: true,
        signUpThroughBranch: true
    },
    KG3: {
        hash: 'dfsakvs7fsdkg',
        name: 'Kangaroo',
        allowRestricted: {
            MI: true
        },
        homeSecurity: true,
        signUpThroughBranch: true
    },
    KG4: {
        hash: 'dfsakvs7fsdkg',
        name: 'Kangaroo',
        allowRestricted: {
            MI: true
        },
        homeSecurity: true,
        signUpThroughBranch: true
    },
    KLT1: {
        hash: 'kdfuiwft1',
        name: 'KLT Insurance',
        agency: true,
        serviceAgreement: true,
        informationId: 'KLT1',
        disableChat: true
    },
    KLT2: {
        hash: 'kdfuiwft12',
        name: 'KLT Insurance',
        agency: true,
        serviceAgreement: true,
        informationId: 'KLT2',
        disableChat: true
    },
    KORT1: {
        hash: 'kort1hash',
        name: 'Kortsen & Associates LLC',
        agency: true
    },
    KST1: {
        hash: 'M99dVc',
        name: 'Kissterra'
    },
    LB1: {
        hash: 'ieddsxds53',
        name: 'Liberty Tax',
        states: {
            IN: 0,
            OH: 0
        }
    },
    LBV1: {
        hash: 'lbv1hash',
        name: 'LBV INSURANCE',
        agency: true
    },
    LG1: {
        hash: 'fddddsv4d',
        name: 'Lendgo',
        states: {
            AL: 2,
            AZ: 2,
            CO: 2,
            GA: 2,
            IL: 2,
            IN: 2,
            KS: 2,
            KY: 2,
            MD: 2,
            MI: 2,
            MO: 2,
            NE: 2,
            NJ: 2,
            NV: 2,
            NY: 2,
            OH: 2,
            OR: 2,
            OK: 2,
            RI: 2,
            SC: 2,
            TX: 2,
            UT: 2,
            VA: 2,
            WI: 2,
            WV: 2
        }
    },
    LGI1: {
        hash: 'lgsdfdw',
        agency: true,
        serviceAgreement: true,
        name: 'Legacy Insures'
    },
    LOGIC: {
        hash: 'logggic',
        name: 'Logic Auto Sales'
    },
    LOUI1: {
        hash: 'loui1hash',
        name: 'Lou Insurance & Financial Services Inc',
        agency: true
    },
    LNSTR1: {
        hash: 'lnstr1hash',
        name: 'Lone Star Insurers',
        agency: true
    },
    MATIC1: {
        hash: 'maticabs',
        name: 'Matic',
        agency: true
    },
    MCR1: {
        hash: 'mcr1hash',
        name: 'Mike Crise Agency',
        agency: true
    },
    MIL: {
        hash: 'idnmiltas',
        agency: true,
        serviceAgreement: true,
        name: 'Milnor Insurance',
        informationId: 'MIL'
    },
    MILO1: {
        hash: 'milo1hash',
        name: 'MILOSEVIC INSURANCE AGENCY',
        agency: true
    },
    MMS1: {
        hash: '4mmsssGIA7',
        name: 'Midwest Motor Supply',
        states: {
            AZ: 2,
            IL: 2,
            IN: 2,
            MD: 2,
            MO: 2,
            OH: 2,
            OK: 2,
            TX: 2
        }
    },
    MMTCH1: {
        hash: 'mmtch1hash',
        name: 'Mix & Match Insurance Brokerage LLC',
        agency: true
    },
    MNSTY1: {
        name: 'Mainstay Insurance Agency',
        agency: true,
        serviceAgreement: true,
        hash: 'eui983lamnstyi'
    },
    MTRO1: {
        hash: 'mtro1hash',
        name: 'Metroplex Insurance & Financial Group LLC',
        agency: true
    },
    MVP: {
        name: 'MVP Insurance Agency',
        agency: true,
        serviceAgreement: true,
        hash: 'qe9df91d5jh'
    },
    MWBG: {
        name: 'Mark Wahlberg Buick GMC',
        hash: '8fed6e5er'
    },
    MWC: {
        name: 'Mark Wahlberg Chevy',
        hash: 'qwhj8zd21s'
    },
    MYLO1: {
        hash: 'klzujoi1jk',
        name: 'Mylo Insurance Solutions',
        agency: true
    },
    NEX1: {
        hash: 'fdsanexe',
        name: 'Nexar',
        states: {
            AZ: 2,
            CO: 2,
            IL: 2,
            IN: 2,
            MD: 2,
            MI: 2,
            MO: 2,
            NE: 2,
            OH: 2,
            OK: 2,
            PA: 2,
            UT: 2,
            WI: 2
        }
    },
    NEXTB1: {
        hash: 'dfvnyds3',
        name: 'Nextbase',
        states: {
            AZ: 2,
            CO: 2,
            IL: 2,
            IN: 2,
            MD: 2,
            MI: 2,
            MO: 2,
            NE: 2,
            OH: 2,
            OK: 2,
            TX: 2,
            UT: 2,
            WI: 2
        }
    },
    NMIB1: {
        hash: 'nmibd33f1',
        agency: true,
        serviceAgreement: true,
        name: 'NMH Insurance Agency',
        informationId: 'NMIB1'
    },
    NMIB2: {
        hash: 'nmib2hash',
        agency: true,
        serviceAgreement: true,
        name: 'NMH Insurance Agency',
        informationId: 'NMIB2'
    },
    NN1: {
        name: 'Nissan North',
        hash: 'ml1dsazd6s4'
    },
    NW1: {
        hash: '4GIA7',
        name: 'NWOSS',
        homeSecurity: true,
        states: {
            IN: 2,
            MD: 2,
            OH: 2,
            OK: 2
        }
    },
    NWE: {
        hash: '4GIA7E',
        name: 'NWOSS',
        homeSecurity: true,
        states: {
            IN: 2,
            OH: 2,
            OK: 2
        }
    },
    NYLE: {
        name: 'Nyle Maxwell CJDR',
        hash: 'gf5nyletg'
    },
    OAC: {
        hash: 'noac33f1',
        agency: true,
        serviceAgreement: true,
        name: 'AAA Ohio Auto Club',
        informationId: 'OAC'
    },
    OBB: {
        hash: 'ruobbiri',
        name: 'Olive Branch Brokerage, LLC',
        agency: true,
        serviceAgreement: true,
        disableChat: true,
        informationId: 'OBB'
    },
    ORL1: {
        name: 'OpenRoad Lending',
        hash: '1mq7mds98rf',
        hideFromOffers: true
    },
    ORL2: {
        name: 'OpenRoad Lending',
        hash: '1mq7mds98rf2',
        states: {
            AZ: 1,
            CO: 1,
            IL: 1,
            IN: 1,
            MD: 1,
            MI: 1,
            MO: 1,
            NE: 1,
            OH: 1,
            OK: 1,
            TX: 1,
            UT: 1,
            WI: 1
        }
    },
    ORLE: {
        name: 'OpenRoad Lending Employees',
        hash: '1mq7mds98rfE',
        states: {
            TX: 2
        }
    },
    PC1: {
        hash: '8dhjv63jndv',
        name: 'Panda ECS',
        states: {
            AZ: 0,
            OH: 0,
            IL: 0,
            IN: 0,
            MO: 0,
            TX: 0
        }
    },
    PCA1: {
        hash: 'pf7chjaca',
        name: 'Protection Connection Agency',
        agency: true
    },
    PCA2: {
        hash: 'pf7chjaca2',
        name: 'Protection Connection Agency',
        agency: true
    },
    PCJD: {
        name: 'Performance Chrysler Jeep Dodge',
        hash: 'fds46nuw332'
    },
    PI1: {
        hash: 'pi77dd',
        name: 'Partner Insurance',
        agency: true,
        serviceAgreement: true,
        informationId: 'PI1'
    },
    PMC: {
        hash: 'dfsjiopdmp',
        name: 'Protect My Car',
        informationId: 'PMC'
    },
    PRTL1: {
        hash: 'prtl1hash',
        name: 'Portal, LLC',
        agency: true
    },
    PRM1: {
        hash: 'prm1hash',
        name: 'Priority Risk Management',
        agency: true
    },
    PUP1: {
        hash: 'pup58df',
        name: 'Puptown Lounge',
        states: {
            OH: 2
        }
    },
    PVD1: {
        hash: 'pdjvdspvd1',
        name: 'Provide Financial',
        informationId: 'PVD1'
    },
    PX1: {
        hash: 'fdsklbvudpx',
        name: 'PX'
    },
    QAONLINE: {
        hash: 'qaodlneid',
        agency: true,
        serviceAgreement: true,
        name: 'Quantum Assurance Online',
        informationId: 'QAONLINE'
    },
    QI1: {
        hash: 'qifdsnkl;734nd',
        agency: true,
        serviceAgreement: true,
        name: 'QuickInsured',
        informationId: 'QI1'
    },
    QI2: {
        hash: 'qifdsnkl;734nd22',
        name: 'QuickInsured',
        agency: true,
        serviceAgreement: true,
        informationId: 'QI2'
    },
    QL1: {
        hash: '6Tpye',
        name: 'Rocket Mortgage',
        shouldBeNotified: true,
        mortgageLender: true,
        featuredProvider: true,
        states: {
            AL: 2,
            AZ: 2,
            CO: 2,
            GA: 2,
            IL: 2,
            IN: 2,
            KS: 2,
            KY: 2,
            MD: 2,
            MI: 2,
            MO: 2,
            NE: 2,
            NJ: 2,
            NV: 2,
            NY: 2,
            OH: 2,
            OR: 2,
            OK: 2,
            RI: 2,
            SC: 2,
            TX: 2,
            UT: 2,
            VA: 2,
            WI: 2,
            WV: 2
        }
    },
    QLE: {
        hash: '6TpyeE',
        name: 'Rocket Mortgage',
        shouldBeNotified: true,
        mortgageLender: true,
        featuredProvider: true,
        states: {
            AL: 2,
            AZ: 2,
            CO: 2,
            GA: 2,
            IL: 2,
            IN: 2,
            KS: 2,
            KY: 2,
            MD: 2,
            MI: 2,
            MO: 2,
            NE: 2,
            NJ: 2,
            NV: 2,
            NY: 2,
            OH: 2,
            OR: 2,
            OK: 2,
            RI: 2,
            SC: 2,
            TX: 2,
            UT: 2,
            VA: 2,
            WI: 2,
            WV: 2
        }
    },
    QWZ1: {
        hash: 'f90o1ljlsxk',
        name: 'QuoteWizard'
    },
    QWZS: {
        hash: '9rfnlkzvc987qlk',
        name: 'Quote Wizard Solutions',
        agency: true
    },
    RACE: {
        hash: 'dsfsd8ewf',
        name: 'Race Day Mortgage',
        states: {
            OH: 1
        }
    },
    RDRS1: {
        hash: 'rdrs1hash',
        name: 'Red Rose Insurance LLC',
        agency: true
    },
    RGD1: {
        hash: 'rgd1hash',
        name: 'Renegade Insurance LLC',
        agency: true
    },
    RGTLP1: {
        hash: 'rgtlp1hash',
        name: 'Reliance Group Texas, L.P.',
        agency: true,
        serviceAgreement: true,
        informationId: 'RGTLP1'
    },
    RH1: {
        name: 'Roush Honda',
        hash: 'gf5rh1d98g'
    },
    RHBS1: {
        hash: 'rhbs1hash',
        name: 'R Hobbs Insurance Agency Inc',
        agency: true
    },
    RI1: {
        hash: 'rutldiri',
        name: 'Rutledge Insurance Group',
        agency: true,
        serviceAgreement: true,
        informationId: 'RI1'
    },
    RIC1: {
        name: 'Ricart Auto',
        informationId: 'RIC1',
        hash: 'gf5su6dd98g'
    },
    RM1: {
        hash: 'iwp1y',
        name: 'Revolution Mortgage',
        mortgageLender: true,
        mortgageClause: 'RM1',
        splitLeadID: true,
        allowRestricted: {
            MI: true
        },
        states: {
            AL: 2,
            CO: 2,
            IN: 2,
            KS: 2,
            KY: 2,
            MD: 2,
            OH: 2,
            OK: 2,
            PA: 2,
            TX: 2,
            UT: 2,
            VA: 2,
            WI: 2,
            WV: 2
        }
    },
    RM2: {
        hash: 'iwp2y',
        name: 'Revolution Mortgage',
        mortgageLender: true,
        mortgageClause: 'RM1',
        splitLeadID: true,
        allowRestricted: {
            MI: true
        },
        states: {
            AL: 2,
            CO: 2,
            IN: 2,
            KS: 2,
            KY: 2,
            MD: 2,
            OH: 2,
            OK: 2,
            PA: 2,
            TX: 2,
            UT: 2,
            VA: 2,
            WI: 2,
            WV: 2
        }
    },
    RME: {
        hash: 'iwp1yE',
        name: 'Revolution Mortgage',
        mortgageLender: true,
        mortgageClause: 'RM1',
        splitLeadID: true,
        allowRestricted: {
            MI: true
        },
        states: {
            AL: 2,
            CO: 2,
            IN: 2,
            KS: 2,
            KY: 2,
            MD: 2,
            OH: 2,
            OK: 2,
            PA: 2,
            TX: 2,
            UT: 2,
            VA: 2,
            WI: 2,
            WV: 2
        }
    },
    ROGRSK1: {
        hash: 'rogrsk1hash',
        name: 'Rogue Risk LLC',
        agency: true
    },
    ROND1: {
        hash: 'rond1hash',
        name: 'Rondon Insurance Services',
        agency: true
    },
    ROYLT1: {
        hash: 'roylt1hash',
        name: 'Royalty Insurance Agency',
        agency: true
    },
    RS1: {
        hash: 'sd9nver89',
        name: "Ritchie's Security"
    },
    RSWL1: {
        hash: 'rswl1hash',
        name: 'RiskWell',
        agency: true
    },
    RVE: {
        hash: 'klfdsuiwef8',
        name: 'RevelIT',
        states: {
            AZ: 2,
            IL: 2,
            IN: 2,
            MD: 2,
            MO: 2,
            OH: 2,
            OK: 2,
            PA: 2,
            TX: 2
        }
    },
    SA1: {
        name: 'Superior Auto',
        hash: '17sdad97gh7e'
    },
    SALTY: {
        hash: 'saltY4d',
        name: 'Salty',
        agency: true,
        serviceAgreement: true,
        skipScheduledCall: true
    },
    SAS1: {
        hash: 'dfkjvfusad87sas',
        name: 'Shook Auto Sales'
    },
    SGIA1: {
        hash: 'SGIA1hash',
        agency: true,
        serviceAgreement: true,
        name: 'Southern Group Insurance Agency, Inc.'
    },
    SHP1: {
        hash: 'shp3789jv7d',
        name: 'Shepherd Insurance',
        agency: true
    },
    SI1: {
        hash: 'dugrndk5',
        agency: true,
        serviceAgreement: true,
        name: 'Obie',
        informationId: 'SI1'
    },
    SI2: {
        hash: 'dugrndk5',
        name: 'Obie',
        agency: true,
        serviceAgreement: true,
        informationId: 'SI1'
    },
    SIG1: {
        hash: 'ddfdsiwesdmd',
        name: 'Steven Insurance Group'
    },
    SIGM1: {
        hash: 'dfkl44sdfisg',
        name: 'Signature Mortgage',
        allowRestricted: {
            MI: true
        },
        states: {
            CO: 2,
            IN: 2,
            OH: 2
            // TX: 2 - for 11/15/2021
        }
    },
    SIM1: {
        hash: 'sim1hash',
        name: 'Simply Insured, LLC',
        agency: true
    },
    SIMPLY1: {
        hash: 'sbnid134',
        name: 'SimpliSafe',
        informationId: 'SIMPLY1',
        homeSecurity: true,
        signUpThroughBranch: true
    },
    SKYLGHT1: {
        hash: 'dkfdhksd',
        agency: true,
        serviceAgreement: true,
        name: 'Skylight Insurance',
        informationId: 'SKYLGHT1'
    },
    SMAFIN1: {
        name: 'SmartFinancial',
        hash: 'cjliej1290clkj'
    },
    SMAZ: {
        hash: 'dfskazxusscs',
        name: 'SmartChoice AZ',
        agency: true,
        serviceAgreement: true,
        informationId: 'SMAZ'
    },
    SMIN: {
        hash: 'dfskincxusscs',
        name: 'SmartChoice IN',
        agency: true,
        serviceAgreement: true,
        informationId: 'SMIN'
    },
    SMMI1: {
        hash: 'smmi1hash',
        name: 'Clement Insurance, LLC',
        agency: true
    },
    SMOH: {
        hash: 'dfskj;vcxusscs',
        name: 'SmartChoice',
        agency: true
    },
    SMOK: {
        hash: 'dfskiokusscs',
        name: 'SmartChoice OK',
        agency: true,
        serviceAgreement: true,
        informationId: 'SMOK'
    },
    SMRT1: {
        hash: 'smrt1hash',
        name: 'Smart Insured Solutions, LLC',
        agency: true
    },
    SMTX: {
        hash: 'intd79f',
        agency: true,
        serviceAgreement: true,
        name: 'SmartChoice Texas'
    },
    SMUN1: {
        hash: 'smun1hash',
        name: 'Smart Universal Insurance',
        agency: true
    },
    TAS: {
        hash: 'idnfusitas',
        name: 'Insuritas',
        agency: true,
        serviceAgreement: true,
        informationId: 'TAS'
    },
    TAS2: {
        hash: 'idnfusitas2',
        name: 'Insuritas',
        agency: true,
        serviceAgreement: true,
        informationId: 'TAS'
    },
    TATUM1: {
        hash: '3kjs9tatum1',
        agency: true,
        serviceAgreement: true,
        informationId: 'TATUM1'
    },
    TATUM2: {
        hash: '3kjs9tatum2',
        agency: true,
        serviceAgreement: true,
        informationId: 'TATUM2'
    },
    TATUM3: {
        hash: '3kjs9tatum3',
        agency: true,
        serviceAgreement: true,
        informationId: 'TATUM3'
    },
    TBDS1: {
        hash: 'tbds1hash',
        name: 'Tisha Brandes Insurance Services LLC',
        agency: true
    },
    TBG: {
        name: 'Twins Buick GMC',
        hash: '8eqwi9sd4fg5'
    },
    TC1: {
        hash: 'Riumk',
        name: 'Total Choice',
        mortgageLender: true,
        states: {
            IN: 2,
            MD: 2,
            OH: 2,
            OK: 2
        }
    },
    TCP: {
        name: 'Toyota of Cedar Park',
        hash: 'gf5stcptg'
    },
    TCP1: {
        hash: 'tcp1hash',
        name: 'The Coverage Pro',
        agency: true
    },
    TEST: {
        hash: 'test',
        name: 'Test Agency',
        shouldBeNotified: true,
        agency: true,
        serviceAgreement: true,
        informationId: 'TEST'
    },
    TEST1: {
        hash: 'test1',
        name: 'Test Agency 1',
        shouldBeNotified: true,
        agency: true,
        serviceAgreement: true,
        informationId: 'TEST1'
    },
    TEST2: {
        hash: 'test2',
        name: 'Test Agency 2',
        shouldBeNotified: true,
        agency: true,
        serviceAgreement: true,
        informationId: 'TEST2'
    },
    TEST3: {
        hash: 'test3',
        name: 'Test Agency 3',
        shouldBeNotified: true,
        agency: true,
        serviceAgreement: true,
        informationId: 'TEST3'
    },
    TEST4: {
        hash: 'test4',
        name: 'Test Agency 4',
        shouldBeNotified: true,
        agency: true,
        serviceAgreement: true,
        informationId: 'TEST4'
    },
    TEST5: {
        hash: 'test5',
        name: 'Test Agency 5',
        shouldBeNotified: true,
        agency: true,
        serviceAgreement: true,
        informationId: 'TEST5'
    },
    TCE: {
        hash: 'RiumkE',
        name: 'Total Choice',
        mortgageLender: true,
        states: {
            IN: 2,
            MD: 2,
            OH: 2,
            OK: 2
        }
    },
    TGS1: {
        hash: 'tgs1hash',
        name: 'TGS Insurance Agency',
        agency: true
    },
    THRV1: {
        hash: 'thrv1hash',
        name: 'Thrive Insurance',
        agency: true
    },
    TLY1: {
        hash: 'zAn63Z8emHffr6Wv',
        name: 'Transparent.ly'
    },
    TRELC: {
        hash: 'f9trelcc',
        name: 'Trellis Connect',
        agency: true,
        serviceAgreement: true,
        disableChat: true
    },
    TRESL1: {
        name: 'Tresl',
        informationId: 'TRESL1',
        hash: 'treslhash'
    },
    TXCL1: {
        hash: 'txcl1hash',
        name: 'Texas Classic Insurance',
        agency: true
    },
    UB1: {
        hash: 'dfdsk8e',
        name: 'Unbiased Auto',
        states: {
            TX: 2
        }
    },
    UNQ1: {
        hash: 'unq1hash',
        agency: true,
        name: 'Unique Insurance Agency LLC'
    },
    UR1: {
        hash: 'YQfPs',
        name: 'Upside Roofing',
        states: {
            IN: 2,
            MD: 2,
            OH: 2,
            OK: 2
        }
    },
    UTYA1: {
        hash: 'utya1hash',
        name: 'Unity AIA',
        agency: true
    },
    URE: {
        hash: 'YQfPsE',
        name: 'Upside Roofing',
        states: {
            IN: 2,
            MD: 2,
            OH: 2,
            OK: 2
        }
    },
    VENS1: {
        hash: 'VENS1hash',
        agency: true,
        serviceAgreement: true,
        name: 'Venus Insurance Group'
    },
    VI1: {
        hash: 'vi3dfklv8',
        name: 'Voldico Insurance',
        agency: true
    },
    VILLAGE: {
        hash: 'vidfsiewf',
        name: 'Village Ford',
        informationId: 'VILLAGE'
    },
    VGHN1: {
        hash: 'vghn1hash',
        name: 'Vaughan Insurance Agency LLC',
        agency: true
    },
    VM1: {
        hash: 'TSjFy',
        name: 'Victory Mortgage',
        states: {
            IN: 2,
            MD: 2,
            OH: 2,
            OK: 2
        }
    },
    VME: {
        hash: 'TSjFyE',
        name: 'Victory Mortgage',
        states: {
            IN: 2,
            MD: 2,
            OH: 2,
            OK: 2
        }
    },
    VNZLA: {
        hash: 'ciconzds92',
        name: 'Vonzella',
        agency: true,
        serviceAgreement: true,
        disableChat: true
    },
    VS1: {
        hash: 'dd8y4R',
        name: 'Vector Security',
        homeSecurity: true,
        states: {
            IN: 2,
            MD: 2,
            OH: 2,
            OK: 2
        }
    },
    VSE: {
        hash: 'dd8y4RE',
        name: 'Vector Security',
        homeSecurity: true,
        states: {
            IN: 2,
            MD: 2,
            OH: 2
        }
    },
    VIV1: {
        hash: 's2LPmaa',
        name: 'Vivint Smart Home Agency',
        agency: true,
        serviceAgreement: true,
        informationId: 'VIV1',
        skipScheduledCall: true
    },
    VV1: {
        hash: 'FuNUN',
        name: 'Vivint Smart Home',
        homeSecurity: true,
        achWildcard: true,
        skipScheduledCall: true,
        states: {
            AZ: 2,
            IL: 2,
            IN: 2,
            MD: 2,
            MO: 2,
            OH: 2,
            TX: 2
        },
        informationId: 'VV1'
    },
    VV2: {
        hash: 's2LPm',
        name: 'Vivint Smart Home',
        homeSecurity: true,
        achWildcard: true,
        skipScheduledCall: true,
        disableChat: true,
        states: {
            CO: 2,
            IL: 2,
            IN: 2,
            MD: 2,
            MO: 2,
            OH: 2
        },
        informationId: 'VV1'
    },
    VV3: {
        hash: 's2LPm4',
        name: 'Vivint Smart Home',
        homeSecurity: true,
        skipScheduledCall: true,
        disableChat: true,
        states: {
            AL: 2,
            AZ: 2,
            CO: 2,
            GA: 2,
            IL: 2,
            IN: 2,
            KS: 2,
            KY: 2,
            MD: 2,
            MI: 2,
            MO: 2,
            NE: 2,
            NJ: 2,
            NV: 2,
            NY: 2,
            OH: 2,
            OR: 2,
            OK: 2,
            RI: 2,
            SC: 2,
            TX: 2,
            UT: 2,
            VA: 2,
            WI: 2,
            WV: 2
        },
        informationId: 'VV1'
    },
    VVE: {
        hash: 's2LPmE',
        name: 'Vivint Smart Home',
        homeSecurity: true,
        skipScheduledCall: true,
        states: {
            IL: 2,
            IN: 2,
            MO: 2,
            OH: 2
        },
        informationId: 'VV1'
    },
    WCI1: {
        hash: 'wci1hash',
        name: 'White Clay Insurance',
        agency: true
    },
    WEA: {
        hash: '918ikjlkj12',
        name: 'Westerville Education Association',
        states: {
            OH: 2
        }
    },
    WEC1: {
        hash: 'dcwec1fisg',
        name: 'WEC Energy Group',
        states: {
            IL: 2,
            IN: 2,
            WI: 2
        }
    },
    WIF1: {
        hash: 'dafrwfg56',
        name: 'What If Media'
    },
    WLGS1: {
        hash: 'wlgs1hash',
        name: 'Wilgus Insurance Agency Inc.',
        agency: true
    },
    WP1: {
        hash: 'dafrwp16',
        name: 'Waypoint Benefits'
    },
    WSTX1: {
        hash: 'wstx1hash',
        name: 'West Texas Insurance Agency',
        agency: true
    },
    WXFRD1: {
        hash: 'wxfrd1hash',
        agency: true,
        name: 'Wexford Insurance, LLC'
    },
    WYL1: {
        hash: 'dfkjvfusad87',
        name: 'Wyler'
    },
    YA: {
        hash: 'younaldef',
        name: 'Young Alfred',
        agency: true,
        serviceAgreement: true,
        informationId: 'YA1'
    },
    YA1: {
        hash: 'younaldef',
        name: 'Young Alfred',
        agency: true,
        serviceAgreement: true,
        informationId: 'YA1'
    },
    EXEC1: {
        hash: '98vlkjwdv',
        agency: true
    },
    YP1: {
        hash: 'ypdldfe',
        name: 'Your Policy',
        agency: true,
        serviceAgreement: true,
        informationId: 'YP1'
    },
    ZB1: {
        hash: 'thewnvsapfew',
        name: 'The Zebra',
        agency: true
    },
    /** For testing purposes */
    SEAMLESS1: {
        hash: 'SEAMLESS1',
        name: 'SEAMLESS1'
    },
    ...largeAgencyCodes
};
export default affinityGroups;
