import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ActionButton } from 'core/components/action-button';
import { FormField } from 'core/components/form';
import withDatePicker from 'core/components/with-date-picker';
import { awsDateFormatter } from 'core/helpers/formatters';
import { withStore } from 'core/store';
import sub from 'date-fns/sub';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import styles from './hold-payment-form.styles';

const HoldPaymentForm = ({
  classes,
  close,
  hasBillingHold,
  store: {
    account: {
      policies: {
        policy: { policy, setChanged }
      }
    }
  }
}) => {
  const { setFieldValue } = useFormikContext();
  return (
    <Grid direction="column" container>
      <Grid container item alignItems="center" className={classes.container}>
        <FormField
          name="billingHoldUntil"
          type="date"
          label="Hold Payment Until"
          disableFuture={false}
          mode="dark"
          xs={3}
        />
        <div className={classes.right}>
          <Button
            onClick={() => {
              setChanged(true);
            }}
            variant="contained"
            color="secondary"
            className={classes.submit}
            xs={3}
            disabled={hasBillingHold}
          >
            Confirm
          </Button>
          <Button
            onClick={() => {
              /* If there is no billing hold then the default value for this field should be null, or if it must be a date,
              it should be one month before the effective date of the policy, so it doesn't block billing.
              (We want to bill renewals before they start so it shouldn't be the effective date). */
              const oneMonthBeforeEffective = sub(new Date(policy.effectiveDate), { months: 1 });
              setFieldValue('billingHoldUntil', awsDateFormatter(oneMonthBeforeEffective));
              setChanged(true);
            }}
            variant="contained"
            color="secondary"
            className={classes.submit}
            xs={3}
            disabled={!hasBillingHold}
          >
            Cancel Hold
          </Button>
          <ActionButton type="close" alt="close form" onClick={close} />
        </div>
      </Grid>
    </Grid>
  );
};

HoldPaymentForm.propTypes = {
  classes: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  hasBillingHold: PropTypes.bool.isRequired,
  store: PropTypes.object.isRequired
};

export default flowRight(withDatePicker, withStyles(styles), withStore)(HoldPaymentForm);
