function makeManyCodes(prefix, namePrefix, count, disableChat, informationId) {
    const codes = {};
    for (let i = 1; i <= count; i += 1) {
        codes[`${prefix}${i}`] = {
            hash: `macgf${i}${prefix}`,
            agency: true,
            informationId: informationId || `${prefix}${i}`,
            name: `${namePrefix} ${i}`,
            disableChat
        };
    }
    return codes;
}
export default {
    ...makeManyCodes('AA', 'Agents Alliance', 8, true),
    ...makeManyCodes('ADIG', 'Advisor Insurance Group', 1, true),
    ...makeManyCodes('AGHRO', 'Agent Hero', 375, true),
    ...makeManyCodes('AMS', 'AmSuisse', 33, true),
    ...makeManyCodes('BCHMRK', 'Benchmark', 2, true),
    ...makeManyCodes('CISS', 'Colorado Insurance', 100, true, 'CISS'),
    ...makeManyCodes('HRZ', 'HRZ', 40, true),
    ...makeManyCodes('INT', 'Integra Insurance Services', 32, true),
    ...makeManyCodes('KOVKG', 'Kover King', 1, true),
    ...makeManyCodes('OAC', 'Ohio Auto Club', 4, true),
    ...makeManyCodes('PP', 'Perfect Policy', 15, true),
    ...makeManyCodes('RGTLP', 'Reliance Group Texas, L.P.', 5, true),
    ...makeManyCodes('RHK', 'Randy House and Associates', 3, true),
    ...makeManyCodes('SE0001', 'Select Insurance Markets- One80', 40, true),
    ...makeManyCodes('SGIA', 'Southern Group Insurance Agency', 1, true),
    ...makeManyCodes('SMAZ', 'Smart Choice (AZ)', 21, true),
    ...makeManyCodes('SMCH', 'Smart Choice', 1, true),
    ...makeManyCodes('SMCO', 'Smart Choice (CO)', 4, true),
    ...makeManyCodes('SMIN', 'Smart Choice (IN)', 32, true),
    ...makeManyCodes('SMMD', 'Smart Choice (MD)', 29, true),
    ...makeManyCodes('SMOH', 'Smart Choice (OH)', 75, true),
    ...makeManyCodes('SMOK', 'Smart Choice (OK)', 1, true),
    ...makeManyCodes('SMPA', 'Smart Choice (PA)', 27, true),
    ...makeManyCodes('SMTX', 'Smart Choice (TX)', 90, true),
    ...makeManyCodes('TWFG', 'TWFG Insurance Services, LLC', 188, true),
    ...makeManyCodes('QA', 'Quantum Assurance', 35, false),
    ...makeManyCodes('TOP', 'TOP Group', 11, true),
    ...makeManyCodes('VIF', 'Voldico Franchise', 50, true),
    ...makeManyCodes('VIN', 'Voldico Network', 50, true)
};
