import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { FormField } from 'core/components/form';
import Section from 'core/components/section';
import useStyles from './settings.styles';

const LeadSourceSettings = ({ canModifyAffinityAndLeadSource, leadSourceOptions, affinityOptions }) => {
  const classes = useStyles();

  return (
    <Section title="Offer Source" type="SubSection">
      <div className={classes.discountContainer}>
        {canModifyAffinityAndLeadSource && (
          <Grid container item xs={12} className={classes.sectionWithLine}>
            <Grid container item xs={12} spacing={5}>
              <FormField
                label="Lead Source"
                xs={8}
                type="select"
                name="leadSource"
                options={leadSourceOptions}
                mode="dark"
              />
              <FormField
                label="Affinity Code"
                xs={4}
                type="autocomplete"
                name="global.affinity"
                options={affinityOptions}
                mode="dark"
              />
            </Grid>
          </Grid>
        )}
      </div>
    </Section>
  );
};

LeadSourceSettings.propTypes = {
  leadSourceOptions: PropTypes.array.isRequired,
  affinityOptions: PropTypes.array.isRequired,
  canModifyAffinityAndLeadSource: PropTypes.bool
};

LeadSourceSettings.defaultProps = {
  canModifyAffinityAndLeadSource: false
};

export default LeadSourceSettings;
