// pass billing day of month to calculate monthly, omit for pay in full
export const getRenewalChargeDate = (effectiveDate, policyType, monthlyBillingDay) => {
  const calculateDayAndMonth = () => {
    const isMonthly = !!monthlyBillingDay;
    const effectiveDateDate = new Date(effectiveDate);
    const effectiveDay = effectiveDateDate.getDate();
    const billingMonth = effectiveDateDate.getMonth() + (policyType === 'H' ? 12 : 6);
    const billingDate = effectiveDateDate;

    if (isMonthly) {
      billingDate.setDate(monthlyBillingDay);
      if (effectiveDay <= monthlyBillingDay) {
        // in this case, we'll charge twice in one month
        // if effectiveDay === monthlyBillingDay,
        // down payment and 1st installment would be charged on effective day.
        billingDate.setMonth(billingMonth - 1);
      } else {
        billingDate.setMonth(billingMonth);
      }
    }

    if (!isMonthly) {
      billingDate.setMonth(billingMonth);
      billingDate.setDate(effectiveDay - 14);
    }
    return billingDate;
  };

  const date = calculateDayAndMonth().toISOString().split('T')[0].split('-');

  return `${date[1]}/${date[2]}/${date[0]}`;
};
