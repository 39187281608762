import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import flowRight from 'lodash-es/flowRight';
import { Form, useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';

import { Label } from 'core';
import Field from 'core/components/form/form.v2';
import Section from 'core/components/section';
import { useStore } from 'core/store';
import { NotificationCard } from 'core/components/notification-card';
import { checkAdditionalCoverages } from 'core/helpers/quoter.service';
import { withToast } from 'core/components/toast';
import { CoverageList } from './coverage-list';
import PurchaseOffSite from '../purchase-off-site';
import useStyles from './additionals.styles';

const Additionals = ({ toast, fromPolicy, disabled, repEmail }) => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const { offer: offerStore, account: accountStore } = useStore();
  const { includeEarthquake, includeFlood } = values;
  const data = fromPolicy ? accountStore.policies.policy.policy : offerStore;
  const policyType = fromPolicy ? data.policyType : data.selectedOption;
  const { offer } = data;
  const coverageA = offer.quote.homeCoverage.coverageA;
  const option = offer.options.find((o) => o.type === policyType) || {};
  const { eqRates, floodRates } = option;
  const { canAddEarthquake, canAddFlood } = checkAdditionalCoverages(offer, policyType);
  const showAdditionalSection = canAddEarthquake || canAddFlood;
  const earthquakePolicyId = fromPolicy && data.policyDetails.earthquakeCoverage?.policyId;
  const floodPolicyId = fromPolicy && data.policyDetails.floodCoverage?.policyId;
  const alreadyPurchased = (includeEarthquake && earthquakePolicyId) || (includeFlood && floodPolicyId);
  const isEqRatesRetrieved = !!eqRates?.options?.length;
  const isFloodRatesRetrieved = !!floodRates?.options?.length;

  const onViewEarthquakePolicy = useCallback(async () => {
    const { error } = await accountStore.policies.policy.getPalomarPolicyDoc(earthquakePolicyId, repEmail);
    if (error) {
      toast.notify({
        type: 'error',
        message: error
      });
    }
  }, [accountStore.policies.policy, earthquakePolicyId, repEmail, toast]);

  const onViewFloodPolicy = useCallback(async () => {
    const response = await accountStore.policies.policy.getPalomarPolicyDoc(floodPolicyId, repEmail);
    if (response.error) {
      toast.notify({
        type: 'error',
        message: response.message
      });
    }
  }, [accountStore.policies.policy, floodPolicyId, repEmail, toast]);

  return (
    <>
      {showAdditionalSection && (
        <Section title="Additional items" type="SubSection">
          <div className={classes.containerDark}>
            <Form disabled={disabled}>
              <Grid container spacing={4} alignItems="flex-end">
                {!alreadyPurchased && (
                  <NotificationCard type="quaternary">
                    <Grid container alignItems="center">
                      Earthquake and flood coverages are handled separately from the home policy. They will need to be
                      bought through a partner after purchase.
                    </Grid>
                  </NotificationCard>
                )}
                {canAddEarthquake && (
                  <>
                    <Field
                      id="includeEarthquake"
                      name="includeEarthquake"
                      type="switch"
                      label="Purchase Earthquake coverage separately"
                      mode="dark"
                      xs={12}
                      fast={false}
                    />
                    {includeEarthquake && (
                      <>
                        {(!fromPolicy || (fromPolicy && !earthquakePolicyId)) &&
                          (isEqRatesRetrieved ? (
                            <CoverageList data={eqRates.options} coverageA={coverageA} />
                          ) : (
                            <Label type="toast"> We couldn’t retrieve any data from Palomar</Label>
                          ))}
                        {fromPolicy && isEqRatesRetrieved && eqRates?.caseLink && (
                          <PurchaseOffSite
                            name="earthquakeCoverage.policyId"
                            partnerName="Palomar"
                            purchaseLink={eqRates.caseLink}
                            onViewPolicy={onViewEarthquakePolicy}
                            policyId={earthquakePolicyId}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
                {canAddFlood && (
                  <>
                    <Field
                      id="includeFlood"
                      name="includeFlood"
                      type="switch"
                      label="Purchase Flood coverage separately"
                      mode="dark"
                      xs={12}
                      fast={false}
                    />
                    {includeFlood && (
                      <>
                        {(!fromPolicy || (fromPolicy && !floodPolicyId)) &&
                          (isFloodRatesRetrieved ? (
                            <CoverageList data={floodRates.options} coverageA={coverageA} />
                          ) : (
                            <Label type="toast"> We couldn’t retrieve any data from Palomar</Label>
                          ))}
                        {fromPolicy && isFloodRatesRetrieved && floodRates?.caseLink && (
                          <PurchaseOffSite
                            name="floodCoverage.policyId"
                            partnerName="Palomar"
                            purchaseLink={floodRates.caseLink}
                            onViewPolicy={onViewFloodPolicy}
                            policyId={floodPolicyId}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Form>
          </div>
        </Section>
      )}
    </>
  );
};

Additionals.propTypes = {
  disabled: PropTypes.bool,
  repEmail: PropTypes.string,
  fromPolicy: PropTypes.bool
};

Additionals.defaultProps = {
  disabled: false,
  fromPolicy: false,
  repEmail: null
};

export default flowRight(observer, withToast)(Additionals);
