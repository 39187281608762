import { isAfter } from 'date-fns';
import {
  affinityGroups,
  policyType as PolicyType,
  paymentMethod as PaymentMethod,
  paymentType,
  windHailDeductibleCanVaryByCounty
} from '@ourbranch/lookups';

export const hasPaymentMethod = ({
  paymentMethods,
  paymentMethod,
  policyType,
  noBindHome = false,
  noBindAuto = false
}) => {
  const { homeownersPaymentMethod, autoPaymentMethod } = paymentMethods;
  return (
    (policyType !== PolicyType.Auto &&
      policyType !== PolicyType.ARBundle &&
      !noBindHome &&
      homeownersPaymentMethod === paymentMethod) ||
    (policyType !== PolicyType.Home && !noBindAuto && autoPaymentMethod === paymentMethod)
  );
};

export const positionToError = (e, formErrors) => {
  const positionConfig = [
    { condition: (fe) => fe.homeEffectiveDate, scrollTo: '[name=homeEffectiveDate]' },
    {
      condition: (fe) => fe.mortgageDetails && fe.mortgageDetails.mortgageHolderName,
      scrollTo: '[id=mortgageSection]'
    },
    { condition: (fe) => fe.attestationsHomeAccepted, scrollTo: '[name=attestationsHomeAccepted]' },
    { condition: (fe) => fe.autoEffectiveDate, scrollTo: '[name=autoEffectiveDate]' },
    { condition: (fe) => fe.attestationsAutoAccepted, scrollTo: '[name=attestationsAutoAccepted]' },
    { condition: (fe) => fe.currentHomeownersCarrier, scrollTo: '[name=currentHomeownersCarrier]' },
    { condition: (fe) => fe.currentAutoCarrier, scrollTo: '[name=currentAutoCarrier]' }
  ];

  for (let i = 0; i < positionConfig.length; i += 1) {
    if (positionConfig[i].condition(formErrors) && document.querySelector(positionConfig[i].scrollTo)) {
      document.querySelector(positionConfig[i].scrollTo).scrollIntoView({ behavior: 'smooth', block: 'center' });
      throw formErrors;
    }
  }

  if (document.querySelector('[name=firstName]')) {
    document.querySelector('[name=firstName]').scrollIntoView({ behavior: 'smooth' });
  }
};

export const getHomePaymentDates = (quote, paymentMethod) => {
  const { home, global } = quote;
  const { purchaseDate } = home;
  const { affinity } = global;
  const maxDate = new Date().setDate(new Date().getDate() + 59);

  const now = new Date();
  const useAffinityDate = affinity && affinityGroups[affinity].achWildcard && paymentMethod === PaymentMethod.ACH;
  let minDate = useAffinityDate ? now.setDate(now.getDate() + 1) : now;

  const purchaseDateToCompare = new Date(`${purchaseDate}T00:00`);
  if (purchaseDateToCompare && isAfter(purchaseDateToCompare, now)) {
    minDate = purchaseDateToCompare.setDate(purchaseDateToCompare.getDate() - 1);
  }

  return { minDate, maxDate };
};

export const getAutoPaymentDates = (quote, paymentMethod) => {
  const { affinity } = quote.global;
  const maxDate = new Date().setDate(new Date().getDate() + 59);
  const now = new Date();
  const useAffinityDate = affinity && affinityGroups[affinity].achWildcard && paymentMethod === PaymentMethod.ACH;
  const minDate = useAffinityDate ? now.setDate(now.getDate() + 1) : now;
  return { minDate, maxDate };
};

export const getPaymentMethods = ({
  paymentMethods,
  policyType,
  noBindHome = false,
  noBindAuto = false,
  hasAbodeProPlan = false
}) => {
  let hasCreditCard;
  let hasACH;
  let hasEscrow;
  if (paymentMethods) {
    hasCreditCard =
      hasPaymentMethod({
        paymentMethods,
        paymentMethod: PaymentMethod.CreditCard,
        policyType,
        noBindHome,
        noBindAuto
      }) || hasAbodeProPlan;
    hasACH = hasPaymentMethod({
      paymentMethods,
      paymentMethod: PaymentMethod.ACH,
      policyType,
      noBindHome,
      noBindAuto
    });
    hasEscrow = hasPaymentMethod({
      paymentMethods,
      paymentMethod: PaymentMethod.Escrow,
      policyType,
      noBindHome,
      noBindAuto
    });
  }
  return { hasCreditCard, hasACH, hasEscrow };
};

export const getPreSaleChecklistConditions = (quote, selectedOptionPolicyType, values) => {
  const {
    correctedAddress,
    drivers,
    selectedOption,
    global,
    noBindHome,
    noBindAuto,
    homeCoverage,
    home,
    includeEarthquake,
    includeFlood
  } = quote;
  const primaryDriver = drivers?.find((driver) => driver.isPrimary);

  const { state } = correctedAddress;
  const { interestedInSeparateWindHail, windHailExclusion } = homeCoverage;
  const policyType = selectedOption || selectedOptionPolicyType;
  const { homeownersPaymentType, autoPaymentType, breakupWithAuto, breakupWithHomeowners } = values;
  const primaryIsMarried = primaryDriver?.maritalStatus === 'M' || primaryDriver?.maritalStatus === 'W';
  const stressFreeSwitch = breakupWithAuto || breakupWithHomeowners;
  const payingInFull =
    (policyType?.includes('H') && homeownersPaymentType === paymentType.OneTime) ||
    (policyType?.includes('A') && autoPaymentType === paymentType.OneTime);
  const payingMonthly =
    (policyType?.includes('H') && homeownersPaymentType === paymentType.Monthly) ||
    (policyType?.includes('A') && autoPaymentType === paymentType.Monthly);
  const microDepositsACH = hasPaymentMethod({
    paymentMethods: global,
    paymentMethod: PaymentMethod.ACH,
    policyType,
    noBindHome,
    noBindAuto
  });
  const noWindHailInformed =
    windHailExclusion &&
    ((state === 'AL' && !interestedInSeparateWindHail) ||
      (windHailDeductibleCanVaryByCounty[state] && windHailDeductibleCanVaryByCounty[state][home.county]));

  return {
    buyingHomePolicyInformed: policyType?.includes('H'),
    allApplicantsConfirmed: primaryIsMarried,
    inColoradoInformed: state === 'CO',
    priorInsuranceEntered: stressFreeSwitch,
    payingInFull,
    payingMonthly,
    homePaymentRemindersUpdated:
      policyType?.includes('H') &&
      (homeownersPaymentType === paymentType.OneTime || homeownersPaymentType === paymentType.Monthly),
    autoPaymentRemindersUpdated:
      policyType?.includes('A') && (autoPaymentType === paymentType.OneTime || autoPaymentType === paymentType.Monthly),
    microDepositsACH,
    noWindHailInformed,
    earthquakeAndFloodInformed: includeEarthquake || includeFlood
  };
};
