export const ABODE_PRO_PLAN_AFFINITY = 'AB1';
export const ABODE_PRO_PLAN_PROVIDER_NAME = 'Abode Pro Plan';

export const NEW_CUSTOMER_HOME_SECURITY = {
  SIGN_UP_TYPE: 'B',
  NO_SIGN_UP_TYPE: 'C'
};

export const CONNECTED_HOME_MONITORED_TYPES = {
  ADVANCED: {
    MONITORED_BY_PARTNER: 'VP',
    MONITORED_BY_NON_PARTNER: 'P',
    NOT_MONITORED: 'N'
  },
  REGULAR: {
    CENTRAL: 'C',
    NOT_MONITORED: 'N',
    SELF: 'S'
  }
};

export const HEALTH_INSURANCE_TYPE = {
  PERSONAL: 'Personal',
  MEDICARE: 'Medicare',
  MEDICAID: 'Medicaid'
};

export const tooltipHoverTexts = {
  dwellingLimit:
    "We recommend at least 10% Incremental Coverage. This way, if it costs more to rebuild your home, you'll have added peace of mind.",
  additionalLivingExpense:
    'If your home becomes uninhabitable due to some kind of covered loss, this coverage provides temporary housing up to the amount you select here.',
  personalPropertyLimit:
    'If something happens to your personal property, like theft or damage due to fire, this covers you.',
  otherStructuresLimit:
    'A lot of homes have property on the premises that is affixed to the ground, but separated from the dwelling. For example: sheds, in ground pools, and detached garages. This provides coverage for those types of things up to the limit you pick.',
  waterBackup: 'Coverage in the event that water backs up from your drainage system and causes damage in your home.',
  coverageDC: 'Day care coverage',
  coverageDR:
    'Coverage for expenses associated with data recovery, in the event of physical loss to electronic data processing equipment or accessories. This can include events that harm or introduce unauthorized instructions or code through a computer, including, but not limited to a computer virus.',
  coverageFairRental:
    'Coverage for rental income resulting from a loss that makes the part of the residence premises you rent to others, or hold for rental, uninhabitable.',
  coverageExtendedPremises:
    'An extension of limited portions of coverage under the homeowners policy for broader premises (Ex: a multi-family dwelling or land).',
  coverageBuildingMaterialsTheft:
    'Coverage in the event of physical loss caused by theft of construction materials and supplies used in connection with your dwelling.',
  coverageSinkhole: 'Coverage that covers damage caused by sinkholes or earth movement',
  coverageOilStorageTank:
    'Optional liability coverage that covers mitigation and clean up of damages caused by oil tank leaks',
  coverageYardAndGarden:
    'Increased limits for the cost of items like trees, landscaping and motorized land vehicles used to service the premises.',
  homeownerProtection:
    'Coverage for your personal property in the event that you rent your home in a home share (ex: Airbnb).',
  guestMedicalLimit:
    "If someone visiting your home sustains an injury on your property, we'll cover their medical expenses up to the limit you select here, whether you're at fault for the accident or not.",
  deductibleAllOther:
    'Deductibles are the amount of money that gets deducted from a claim payment that we make to you.',
  personalLiabilityCoverage:
    "This is coverage for when you're at fault for the property damage or bodily injury to another person.",
  PPALExpenseCoverage:
    'If something happens to your personal property, like theft or damage due to fire, this covers you. If your home becomes uninhabitable due to some kind of covered loss, this coverage provides temporary housing up to the amount you select here.',
  deductibleCollision:
    "This is the amount you'll pay out of pocket in the event your car is damaged in a collision. Your policy will pay the rest, up to the limits outlined in your policy.",
  deductibleComprehensive:
    "This is the amount you'll pay out of pocket in the event your car is damaged through an event not covered under collision. For example: with an animal, fire, hail, or vandalism.",
  limitRental:
    'If you experience a covered collision or comprehensive loss, this covers your transportation (for example, rental cars or rideshare services) while your car is being fixed.',
  roadsideAssistance: 'Select Roadside Assistance to get towing and labor assistance in the event of an emergency.',
  limitUMPD: 'This provides coverage for damage to your car caused by an uninsured driver.',
  coverageLoan:
    "If your car is totaled or stolen, this will cover the difference between your car's current value and what you owe on your loan. | Comprehensive and Collision deductibles are required to enable this coverage.",
  rideSharing:
    "Most commercial auto policies from Uber, Lyft, etc, will provide coverage if you drive for them. Typically, the coverage protects you during times when you are actually driving customers. In many cases though, you aren't covered during times when you are not physically driving customers; for example, you're on the way to pick one up or waiting for one to get into the car. Select this option to extend your coverage to include those times.",
  policyLimitBIPD:
    "Bodily injury coverage has two limits: per person and per accident. The per person limit is the max we'll pay for each person injured in an accident. The per accident limit is the max we'll pay for each accident, regardless of the number of injuries.",
  policyLimitMedicalPayments:
    'This covers you in the event someone is hurt while riding in your car up to the limit you select.',
  umUimBodilyInjury:
    "This is the max we'll pay for medical expenses per person injured / per accident in the event you are in an accident with someone who doesn't have insurance (or enough insurance).",
  umUimPropertyDamage:
    "This is the max we'll pay for property damage expenses in the event you are in an accident with someone who doesn't have insurance (or enough insurance).",
  umEuimBodilyInjury:
    "Uninsured Motorist coverage is required. The limits you select can be less than or equal to your liability limits and must be the same on each vehicle. If you select Uninsured Motorist coverage we will only pay you up to the difference between the at-fault driver's Liability coverage and your Uninsured Motorist coverage limit. If you select Enhanced Underinsured Motorist coverage we will pay you for your damages in addition to the at-fault driver's liability coverage limit up to your Enhanced Underinsured Motorist coverage limit.",
  umpdEumpd:
    'Uninsured Motorist Property Damage coverage is required. The limits you select can be less than or equal to your liability limits and must be the same on each vehicle. If you select Uninsured Motorist Property Damage coverage we will only pay you up to the difference between the at-fault driver’s Liability coverage and your Uninsured Motorist Property Damage coverage limit. If you select Enhanced Underinsured Motorist Property Damage coverage we will pay you for your damages in addition to the at-fault driver’s liability coverage limit up to your Enhanced Underinsured Motorist Property Damage coverage limit.',
  coverageRSExtended:
    'Coverage for roof surfaces up to repair/replacement cost. If not selected, roof claims will be paid based on a roof payment schedule.',
  personalInjuryProtection:
    'This covers medical expenses and lost wages for you and passengers no matter who caused the accident',
  policyLimitPIPME:
    'Coverage for necessary medical care up to the limit you choose. Remember that you’ll need to confirm with your health insurance provider that you have qualifying health coverage if you decide to exclude PIP Medical Expense coverage.',
  policyLimitPIPACR:
    'If you carry Limited Medical Expense, you can add some additional coverage with the Attendant Care Rider.',
  waivedPIPWL: 'If you’re at least 60 years old, you can choose to waive Work Loss benefits.',
  pipResidentsWithQHC:
    'Qualified Health Coverage means that your Health Insurance Provider will cover medical expenses resulting from a motor vehicle accident. Residents covered under Medicare should be counted here.',
  pipExcludedResidents:
    'Drivers with Qualified Health Coverage (QHC), including medicare, can typically be excluded from PIP coverage. After you join the Branch community, you will need to provide proof of QHC through our mobile app for any excluded residents',
  pipAdditionalResidents:
    'This is the number of residents who are on a different MI auto insurance policy where they already have PIP coverage. These residents are not eligible for PIP coverage on this policy and therefore should not be considered when selecting coverages, counting residents with QHC, or counting residents to exclude from PIP coverage',
  lossAssessments:
    'Coverage for common property in a shared community or homeowners association that is damaged by a covered loss and assessed to the insured.',
  buildingCodeCoverage:
    "Helps cover the additional cost of repairing an insured's home up to code if it's damaged by a covered loss.",
  increasedBuildingStructure:
    "Provides additional coverage to repair or rebuild an insured's home when the original limits are not enough.",
  noMonolineAuto:
    'This customer is not eligible to purchase a monoline auto policy due to incidents or violations on one or more drivers.',
  noMonolineAutoWBundle:
    'This customer is not eligible to purchase a monoline auto policy due to incidents or violations on one or more drivers. If this customer already has a home policy with Branch, please link this offer to an existing customer.',
  windHail:
    'The wind/hail deductible is an optional deductible that may help save money on premium. In order to select a wind/hail deductible, select an option that is HIGHER than the all other peril deductible. If you do not want a wind/hail deductible, select the wind/hail deductible option that is equal to the all other peril deductible selection.',
  policyLimitGuestPIP:
    'All drivers must reject No Fault coverage to qualify for Guest PIP coverage of $10k. To waive the No Fault Benefit and add Guest PIP, you will need to contact support for a dev escalation.',
  policyLimitNoFaultPIP:
    'By default we include this coverage. Rejecting this coverage will lower your premium, but also limits your legal rights in the event of an accident. To waive the No Fault Benefit and add Guest PIP, you will need to contact support for a dev escalation.',
  discountCourseKY:
    'Course must be approved by the Transportation Cabinet of Kentucky within the last 5 years. You will be asked for proof of course completion upon purchase.'
};

export const connectedHomeTooltipHoverTexts = {
  moisture: {
    bix: 'These devices detect moisture, placed where leaks can occur (under sinks, near water heater or washer, etc.). Professionally installed and centrally monitored through a company or a detection system installed by a homeowner and self-monitored via an app.',
    nonbix:
      'These devices detect moisture, placed where leaks can occur (under sinks, near water heater or washer, etc.). Typically installed by a homeowner and monitored via an app.'
  },
  smoke: {
    bix: 'Battery or hard-wired smoke detectors; could be a traditional smoke detector or combination detector.',
    nonbix: 'Battery or hard-wired smoke detectors; could be a traditional smoke detector or combination detector.'
  },
  theft: {
    bix: 'Professionally installed and centrally monitored alarm system through a security company or a security system installed by a homeowner and self-monitored via an app.',
    nonbix:
      'Professionally installed and centrally monitored alarm system through a security company or a security system installed by a homeowner and self-monitored via an app.'
  },
  water: {
    bix: 'These devices detect potential water leaks and automatically shut off the water supply to minimize damage. Typically placed on the main water line inside a home and professional installation is usually recommended.',
    nonbix:
      'These devices detect potential water leaks and automatically shut off the water supply to minimize damage. Typically placed on the main water line inside a home and professional installation is usually recommended.'
  },
  motion: {
    nonbix:
      'Professionally installed and centrally monitored motion through a security company or a security system with motion detectors installed by a homeowner and self-monitored via an app.'
  },
  monitoringTypes: {
    bix: 'This discount is for professionally monitored systems. If the devices are not monitored, the discount should be turned off.',
    nonbix:
      'This discount is for professionally monitored systems. If the devices are not monitored, the discount should be turned off'
  },
  homeSecurityPartnerCustomerType:
    'Selecting this box will prompt the home security company to contact the customer to buy the home security system necessary to retain the Connected Home Discount. Available with select home security partnerships only.'
};
