import React, { useMemo, useRef, useEffect, useContext, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { lookupsJson, affinityGroups } from '@ourbranch/lookups';
import flowRight from 'lodash-es/flowRight';
import { getSnapshot } from 'mobx-state-tree';
import { Form, useFormikContext, FieldArray } from 'formik';
import PropTypes from 'prop-types';

import Field from 'core/components/form/form.v2';
import AddButton from 'core/components/add-button';
import RemoveButton from 'core/components/remove-button';
import { withStore } from 'core/store';
import { AuthContext } from 'core/components/auth';
import { Label } from 'core/components/label';
import { formattedAffinityOptions } from 'core/helpers/lookups';
import { connectedHomeTooltipHoverTexts as TooltipHoverTexts } from 'core/helpers/constants';
import withDatePicker from 'core/components/with-date-picker';
import { useErrors } from 'quote/helpers/error-hooks';
import AddressAutocomplete from 'core/components/address-autocomplete';
import { Collapsible } from '../collapsible';
import { Errors } from '../errors';
import useStyles from './applicant-details.styles';
import { applicantCondition } from '../quote-forms/quote-forms.clean';
import { priorAddressCode, propDetailsCode, sections } from '../quote-forms/quote-forms.constants';

const ApplicantDetails = ({
  store,
  setShowPriorAddressCallout,
  setShowPropertyDetailsCallout,
  sectionsToShow,
  setSectionsToShow,
  showAllSectionsClicked,
  setShowAllSectionsClicked
}) => {
  const {
    quote: { errors: quoteErrors }
  } = store;

  // Common function to check if Prior Address or Property Detail are visible.
  const checkVisibility = (source) => source.every((section) => sectionsToShow.includes(section));

  // Get or set if prior address is visible.
  const [priorAddressVisible, setPriorAddressVisible] = useState(checkVisibility(priorAddressCode));

  // Get or set if property details is visible.
  const [propDetailsVisible, setPropDetailsVisible] = useState(checkVisibility(propDetailsCode));

  const errors = useErrors(getSnapshot(quoteErrors), applicantCondition);
  const classes = useStyles();
  const { setFieldValue, setFieldTouched, values, error: formikErrors } = useFormikContext();
  const { isAgency, user, allowedStates, canModifyAffinityAndLeadSource, isInternalSales } = useContext(AuthContext);

  // log so we can quickly see validation issues in production
  // eslint-disable-next-line no-console
  console.log({ formikErrors });

  const { leadSourceOptions, stateOptions, affinityPartnersOptions } = useMemo(() => {
    return {
      leadSourceOptions: lookupsJson.leadSources,
      stateOptions:
        allowedStates.length > 0
          ? lookupsJson.usStates.filter(({ id }) => allowedStates.includes(id))
          : lookupsJson.usStates,
      affinityPartnersOptions: formattedAffinityOptions()
    };
  }, [allowedStates]);

  const affinityAllowsNewCustomerSignUp = useMemo(
    () =>
      Boolean(values.affinity && values.affinity !== 'None' && affinityGroups[values.affinity]?.signUpThroughBranch),
    [values.affinity]
  );

  const [showAllSectionsVisible, setShowAllSectionsVisible] = useState(true);

  useEffect(() => {
    if (typeof isInternalSales == 'boolean') {
      setShowAllSectionsVisible(isInternalSales);
    }
  }, [isInternalSales]);

  useEffect(() => {
    if (isAgency) {
      setFieldValue('leadSource', 'Agency', false);
      setFieldValue('affinity', user.affinityCode, false);
    }
    setFieldValue('rep', user.username, false);
  }, [user, isAgency, setFieldValue]);

  const applicantErrors = useRef(null);

  useEffect(() => {
    if (errors.length && applicantErrors.current) {
      applicantErrors.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }, [errors]);

  useEffect(() => {
    if (values.isNewConstruction === true) {
      setShowPriorAddressCallout(true);
      setShowPropertyDetailsCallout(true);

      const isPriorAddressVisible = checkVisibility(priorAddressCode);
      const isPropDetailsVisible = checkVisibility(propDetailsCode);
      let sectionsToShowCloned = [...sectionsToShow];

      // If new construction is checked and Prior address section is hidden, it will be included in SectionsToShow variable.
      if (!isPriorAddressVisible) {
        sectionsToShowCloned = [...sectionsToShowCloned, ...priorAddressCode];
      }

      // If new construction is checked and Property details section is hidden, it will be included in SectionsToShow variable.
      if (!isPropDetailsVisible) {
        sectionsToShowCloned = [...sectionsToShowCloned, ...propDetailsCode];
      }

      setPriorAddressVisible(isPriorAddressVisible);
      setPropDetailsVisible(isPropDetailsVisible);
      setSectionsToShow(sectionsToShowCloned);
    }

    if (values.isNewConstruction === false) {
      setShowPriorAddressCallout(false);
      setShowPropertyDetailsCallout(false);

      let sectionsToShowCloned = [...sectionsToShow];
      // If new construction is unchecked, Prior address will be hidden and removed for SectionsToShow variable.
      if (!priorAddressVisible) {
        sectionsToShowCloned = sectionsToShowCloned.filter((section) => !priorAddressCode.includes(section));
      }

      // If new construction is unchecked, Property details will be hidden and removed for SectionsToShow variable.
      if (!propDetailsVisible) {
        sectionsToShowCloned = sectionsToShowCloned.filter((section) => !propDetailsCode.includes(section));
      }

      setSectionsToShow(sectionsToShowCloned);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.isNewConstruction]);

  const showAllSections = useCallback(() => {
    setShowAllSectionsClicked(true);
    setSectionsToShow(sections);
  }, [setSectionsToShow, setShowAllSectionsClicked]);

  return (
    <Form>
      {errors.length > 0 && <Errors errors={errors} ref={applicantErrors} />}
      <Collapsible initialOpen title="Applicant Details*">
        <>
          <Field xs={6} type="string" id="firstName" name="firstName" mode="light" label="First Name*" />
          <Field xs={6} type="string" id="lastName" name="lastName" mode="light" label="Last Name*" />
          <AddressAutocomplete id="address" name="address" xs={12} mode="light" label="Address*" />
          <Field xs={8} type="string" id="unit" name="unit" mode="light" label="Floor, unit, building, etc." />
          <Grid item xs={4} className={classes.checkboxContainer}>
            <Field
              type="checkbox"
              id="isNewConstruction"
              name="isNewConstruction"
              mode="light"
              label="New Construction?"
            />
          </Grid>
          <Field xs={4} type="string" id="city" name="city" mode="light" label="City*" />
          <Field
            xs={4}
            type="autocomplete"
            options={stateOptions}
            id="state"
            name="state"
            mode="light"
            label="State*"
            stateSelectForm
          />
          <Field xs={4} type="string" id="zip" name="zip" mode="light" label="Zip Code*" />
          <Field xs={8} type="string" id="email" name="email" mode="light" label="Email" />
          <Field xs={4} type="string" id="phone" name="phone" mode="light" label="Phone Number" />
          <FieldArray
            name="additionalPhoneNumbers"
            render={(arrayHelpers) => (
              <Grid container alignItems="center" spacing={2} justify="flex-start">
                {values.additionalPhoneNumbers?.length !== 0 &&
                  values.additionalPhoneNumbers?.map((_, index) => (
                    <Grid
                      container
                      key={`addtl-phone-${index}`}
                      className={classes.secondaryPhoneContainer}
                      spacing={2}
                    >
                      <Field
                        mode="light"
                        name={`additionalPhoneNumbers.${index}.phoneNumber`}
                        id={`additionalPhoneNumbers.${index}.phoneNumber`}
                        label="Secondary Phone"
                        type="string"
                        xs={4}
                      />

                      <Field
                        mode="light"
                        name={`additionalPhoneNumbers.${index}.note`}
                        id={`additionalPhoneNumbers.${index}.note`}
                        label="Description"
                        type="string"
                        xs={7}
                      />
                      <RemoveButton
                        xs={2}
                        mode="big"
                        onClick={() => {
                          arrayHelpers.remove(index);
                          setFieldTouched('additionalPhoneNumbers');
                        }}
                      />
                      <Field
                        mode="light"
                        name={`additionalPhoneNumbers.${index}.canText`}
                        id={`additionalPhoneNumbers.${index}.canText`}
                        label="Does the potential member agree to receiving texts at this number?"
                        type="checkbox"
                        className={classes.textCheckbox}
                      />
                    </Grid>
                  ))}
                <AddButton
                  type="full"
                  mode="xl"
                  onClick={() => {
                    arrayHelpers.push({ phoneNumber: '', note: '', canText: false });
                    setFieldTouched('additionalPhoneNumbers');
                  }}
                  label="Add an additional phone number"
                />
              </Grid>
            )}
          />
          {canModifyAffinityAndLeadSource && (
            <>
              <Field
                xs={4}
                type="select"
                id="leadSource"
                name="leadSource"
                mode="light"
                label="Lead Source"
                options={leadSourceOptions}
              />
              <Field
                xs={4}
                type="autocomplete"
                id="affinity"
                mode="light"
                name="affinity"
                label="Affinity Partner"
                options={affinityPartnersOptions}
              />
              <Field
                xs={4}
                type="checkbox"
                id="signUpForNewMonitoringService"
                name="signUpForNewMonitoringService"
                mode="light"
                label="Sign up for new monitoring service?"
                tooltipText={TooltipHoverTexts.homeSecurityPartnerCustomerType}
                tooltipLabel="More Info"
                className={classes.newSignUpCheckbox}
                disabled={!affinityAllowsNewCustomerSignUp}
                fast={false}
              />
            </>
          )}
          {showAllSectionsVisible && !showAllSectionsClicked && (
            <Grid item xs={4} className={classes.checkboxContainer}>
              <Label type="action" onClick={showAllSections}>
                Show all fields
              </Label>
            </Grid>
          )}
        </>
      </Collapsible>
    </Form>
  );
};

ApplicantDetails.propTypes = {
  store: PropTypes.object.isRequired,
  setShowPriorAddressCallout: PropTypes.func.isRequired,
  setShowPropertyDetailsCallout: PropTypes.func.isRequired
};

export default flowRight(withDatePicker, withStore)(ApplicantDetails);
