import { Formik } from 'formik';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { Loading } from 'core';
import { AuthContext } from 'core/components/auth';
import { useStore } from 'core/store';
import PriceDetails from './price-details';
import PaymentDetails from './payment-details';
import awsExports from '../../../../aws-exports';
import Footer from '../footer';

const stripePromise = loadStripe(awsExports.stripeKey);

const PaymentTab = observer(({ loadingPreview, handleChangePolicy }) => {
  const session = useContext(AuthContext);
  const {
    account: {
      policies: { policy: store }
    }
  } = useStore();

  const onSubmit = (values) => {
    handleChangePolicy({
      ...store.policy,
      fees: values.fees,
      billingAddInstallments: values.billingAddInstallments,
      billingHoldUntil: values.billingHoldUntil,
      skipImmediateBillOrRefund: values.skipImmediateBillOrRefund
    });
    store.setChanged(false);
  };

  const showFooter = (session.isService || session.isSales) && store.changed;

  if (store.loading) {
    return <Loading type="secondary" />;
  }
  return (
    <Elements stripe={stripePromise}>
      <Formik
        initialValues={{
          billingAddInstallments: 0,
          billingHoldUntil: store.policy ? store.policy.billingHoldUntil : undefined,
          fees: store.policy.fees
        }}
        onSubmit={onSubmit}
      >
        <>
          <PriceDetails />
          <PaymentDetails />
          {showFooter && <Footer title="Save changes" loadingPreview={loadingPreview} />}
        </>
      </Formik>
    </Elements>
  );
});

PaymentTab.propTypes = {
  loadingPreview: PropTypes.bool.isRequired,
  handleChangePolicy: PropTypes.func.isRequired
};

export default PaymentTab;
