import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';
import flowRight from 'lodash-es/flowRight';
import { rentersPolicyAvailable, editableHouseholdMembersStates } from '@ourbranch/lookups';

import AutoBreakdownPreview from 'customer/components/policy/auto-breakdown-preview/preview';
import { withDisabledState } from '../../../customer/disabled-context';
import Coverage from './auto-coverage';
import Cars from './cars';
import CarAssignment from 'common/components/auto/car-assignment';
import Trailers from './trailers';
import Renters from './renters';
import PriorCoverage from './prior-coverage';
import AutoPolicyDetails from './auto-policy-details';

function AutoOffer({ disabled, offer }) {
  const { values } = useFormikContext();

  const showAutoPolicyDetails = editableHouseholdMembersStates.includes(offer.state);
  return (
    <>
      <Cars disabled={disabled} offer={offer} fromOffer />
      <CarAssignment />
      <Trailers disabled={disabled} offer={offer} />
      <Coverage disabled={disabled} />
      {showAutoPolicyDetails && <AutoPolicyDetails disabled={disabled} />}
      <PriorCoverage />

      {rentersPolicyAvailable[offer.state] && offer.selectedOption !== 'AR' && <Renters disabled={disabled} />}
      <AutoBreakdownPreview
        offer={offer?.offer}
        cars={getIn(values, 'cars')}
        autoCoverage={getIn(values, 'autoCoverage')}
      />
    </>
  );
}

AutoOffer.propTypes = {
  disabled: PropTypes.bool,
  offer: PropTypes.object.isRequired
};

AutoOffer.defaultProps = {
  disabled: false
};

export default flowRight(withDisabledState, memo)(AutoOffer);
