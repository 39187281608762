import * as Yup from 'yup';
import { formatISO, isWithinInterval, parseISO, isSameDay, isToday, isFuture } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { getValue, paymentMethod, paymentType } from '@ourbranch/lookups';

import { requiredString } from 'common/helpers/yup-helpers';

Yup.addMethod(Yup.string, 'requiredString', requiredString);

/* FYI
 * address node is already validated as required in the add-interested-party-form.js
 * we don't want to add required again here, because we can load up a policy with an address as null,
 * and we should allow the policy modification to go through without this validation blocking it
 */
const lienHolderValidation = Yup.array(
  Yup.object().shape({
    name: Yup.string().required('Name or Company are required!'),
    address: Yup.object()
      .shape({
        address: Yup.string().nullable(),
        address2: Yup.string().nullable(),
        city: Yup.string().nullable(),
        state: Yup.string().nullable(),
        zip: Yup.string().nullable()
      })
      .nullable(),
    loanNumber: Yup.string().nullable(),
    VIN: Yup.string().nullable()
  })
).nullable();

const partiesValidation = Yup.array(
  Yup.object().shape({
    name: Yup.string().requiredString('Name or Company are required!'),
    relationship: Yup.string().requiredString('Relationship is required'),
    address: Yup.object()
      .shape({
        address: Yup.string().nullable(),
        address2: Yup.string().nullable(),
        city: Yup.string().nullable(),
        state: Yup.string().nullable(),
        zip: Yup.string().nullable()
      })
      .nullable()
  })
).nullable();

const validations = {
  A: lienHolderValidation,
  H: partiesValidation
};

function generateEffectiveDateWarning(currentEffectiveDate, minDate, maxDate) {
  const formattedMinDate = formatISO(minDate).split('T')[0];
  const formattedMaxDate = formatISO(maxDate).split('T')[0];

  if (isSameDay(minDate, maxDate)) {
    // if same day, user cannot back date
    return `Effective date must be ${formattedMinDate}`;
  }
  if (isToday(toDate(currentEffectiveDate)) || isFuture(toDate(currentEffectiveDate))) {
    // if this is true, user can move effective date forward
    return `Effective date must be after ${formattedMinDate}`;
  }
  return `Effective date must be after ${formattedMinDate} and before ${formattedMaxDate}`;
}

export const validationSchema = ({ policyType, minDate, maxDate, values, currentEffectiveDate }) => {
  // don't validate anything if we're cancelling the policy
  if (values.cancel) {
    return Yup.object().shape();
  }

  return Yup.object().shape({
    effectiveDate: Yup.string().test(
      'invalidEffectiveDate',
      `${generateEffectiveDateWarning(currentEffectiveDate, minDate, maxDate)}`,
      (value) => {
        const valueParsed = parseISO(value);
        // for users who cannot backdate: if the new effective date and current effective date are both either today, or in the future, allow changing
        // for users who can backdate: allow moving effective date either 6 months back, or 2 months forward
        return (
          isWithinInterval(valueParsed, { start: minDate, end: maxDate }) ||
          isSameDay(valueParsed, minDate) ||
          isSameDay(valueParsed, maxDate)
        );
      }
    ),
    additionalParties: validations[policyType],
    paymentType: Yup.string().test('valid', 'Invalid payment type for that payment method', function test(value) {
      if (value == null) return true;

      if (
        (value === paymentType.Escrow && this.parent.paymentMethod !== paymentMethod.Escrow) ||
        (this.parent.paymentMethod === paymentMethod.Escrow && value !== paymentType.Escrow)
      ) {
        const paymentMethod = getValue('homeownersPaymentMethod', this.parent.paymentMethod);
        const paymentType = getValue('paymentType', value);
        return this.createError({
          path: this.path,
          message: `${paymentType} payment type is not valid for ${paymentMethod} payment method`
        });
      }

      return true;
    }),
    defaultEscrowAccount: Yup.object().when('paymentMethod', {
      is: paymentMethod.Escrow,
      then: Yup.object().shape({
        mortgageHolderName: Yup.string().test('requireIfNotCancelling', 'Required', function test(value) {
          return value || values.cancel;
        })
      }),
      otherwise: Yup.object().nullable()
    }),
    defaultBankAccount: Yup.object().when('paymentMethod', {
      is: paymentMethod.ACH,
      then: Yup.object()
        .shape({
          id: Yup.string().required().default('')
        })
        .default({ id: undefined }),
      otherwise: Yup.object().nullable()
    })
  });
};
