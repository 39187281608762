import { withStyles } from '@material-ui/core/styles';
import { policyTypes } from '@ourbranch/policy-types';
import dateFnsFormat from 'date-fns/format';
import isFuture from 'date-fns/isFuture';
import { Formik, yupToFormErrors } from 'formik';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { observer } from 'mobx-react';
import _isEqual from 'lodash-es/isEqual';

import { AuthContext } from 'core/components/auth';
import {
  mapAutoDiscountValues,
  mapHomeDiscountValues,
  mapDiscountToInputs,
  awsDateFormatter,
  getAutoMPD
} from 'core/helpers/formatters';
import {
  getDateViolations,
  getCorrectedAttachmentsDescription,
  getCorrectedMPDDescription,
  getMismatchedAttachedEndorsementsFromMPD,
  getMismatchedMPDDescription
} from './helpers';
import { affinityCodeToLabel, affinityLabelToCode } from 'core/helpers/affinity-formatters';
import { Loading } from 'core/components/loading';
import { useToast } from 'core/components/toast';
import { formatInput, getValues, sortItems } from 'core/helpers/scheduled-pp-helper';
import { useStore } from 'core/store';
import { formatUmbrellaValues } from 'common/components/home/coverages/umbrella/umbrella';
import {
  formatValues as formatHomeValues,
  getHighestEducation,
  getOldestResident
} from 'common/components/home/detail/helper';
import { getStartMinMaxDate } from '../dates';
import DetailsForm from './details-form';
import { autoValidation, homeValidation } from './validations';
import { styles } from './details.styles';

const changeType = {
  EditSelectedSegment: 'edit_change',
  NewSegment: 'new_change'
};

const getAttachedEndorsements = (values) => {
  return {
    attachedRenters: !!values.attachedRenters,
    attachedHomeowners: !!values.attachedHomeowners,
    includeUmbrella: !!values.includeUmbrella,
    attachedAuto: !!values.attachedAuto
  };
};

const PolicyDetails = observer(({ loadingPreview, onSegmentPreview, repEmail }) => {
  const {
    account: {
      policies: {
        getAutoPolicyCoverage,
        getHomePolicyDetails,
        getAutoPolicyDetails,
        hasSecondHome,
        list: policies,
        policy: { policy, segment, isAdvancedConnectedHome, geographicState }
      }
    }
  } = useStore();

  // in order to have the accurate umbrella values on Auto, we have to use this function to look at the active home policy details
  const homePolicyDetails = getHomePolicyDetails();

  // in order to calculate the correct MPD on a home policy, we need to use this function to see if renters is attached on the auto
  const autoPolicyDetails = getAutoPolicyDetails();

  // in order to calculate correct MPD on home policies, we need to know if we have a second home
  const secondHome = hasSecondHome();

  const [state, setState] = useState(() =>
    fromJS({
      changed: false,
      changeType: isFuture(new Date(segment.startDate)) ? changeType.EditSelectedSegment : changeType.NewSegment,
      startDate: undefined,
      minDate: undefined,
      maxDate: undefined
    })
  );

  const session = useContext(AuthContext);
  const { canBackDate } = session;
  const toast = useToast();

  // if we're on a home policy, the current segment node in the store is filled with the home policy's segment
  // so we need to grab the autoPolicy from the store and look at it's merged segments or policyDetails for the auto coverage
  // we need autoCoverage even for home policies so we know if the policy is able to include umbrella
  const autoCoverage = policy.policyType === 'A' ? segment.autoCoverage : getAutoPolicyCoverage();

  const initialValues = {
    ...segment,
    auto: segment?.auto
      ? {
          ...segment.auto,
          pipAdditionalResidents: segment?.auto?.pipAdditionalResidents || 0
        }
      : null,
    scheduledPersonalProperty: {
      deductible: segment.scheduledPersonalProperty?.items ? segment.scheduledPersonalProperty?.deductible : null,
      items: segment.scheduledPersonalProperty?.items.length
        ? sortItems(segment.scheduledPersonalProperty?.items.map((item) => getValues(item)))
        : []
    },
    highestEducation:
      policy?.offer?.quote?.people || policy?.offer?.quote?.drivers
        ? getHighestEducation(policy?.offer?.quote?.people || policy?.offer?.quote?.drivers)
        : undefined,
    oldestResident:
      policy?.offer?.quote?.people || policy?.offer?.quote?.drivers
        ? getOldestResident(policy?.offer?.quote?.people || policy?.offer?.quote?.drivers)
        : undefined,
    multiPolicyDiscount:
      policy.policyType === policyTypes.Home ? segment.multiPolicyDiscount : getAutoMPD(segment.multiPolicyDiscount),
    attachedRenters: segment.attachedRenters || !!segment.includeRenters,
    autoCoverage,
    underwritingChanges: false,
    isBix: policy.isBix,
    global: segment?.global
      ? {
          ...segment.global,
          affinity: segment.global.affinity && affinityCodeToLabel(segment.global.affinity)
        }
      : null
  };

  useEffect(() => {
    if (!state.get('startDate') && policy) {
      const { startDate, minDate, maxDate } = getStartMinMaxDate({
        policy,
        editCurrentSegment: state.get('changeType') === changeType.EditSelectedSegment,
        currentSegment: segment,
        canBackDate
      });
      setState(state.set('startDate', startDate).set('minDate', minDate).set('maxDate', maxDate));
    }
  }, [policy, state, segment, canBackDate]);

  useEffect(() => {
    // runs when clicking on a different segment in changes history
    // sets default change type based on effective date of segment: future effective defaults to changeType.EditSelectedSegment.
    if (policy && segment?.segmentId && segment.segmentId !== state.get('segmentId')) {
      const { startDate, minDate, maxDate } = getStartMinMaxDate({
        policy,
        editCurrentSegment: isFuture(new Date(segment.startDate)),
        currentSegment: segment,
        canBackDate
      });
      setState(
        state
          .set('segmentId', segment.segmentId)
          .set(
            'changeType',
            isFuture(new Date(segment.startDate)) ? changeType.EditSelectedSegment : changeType.NewSegment
          )
          .set('changed', false)
          .set('startDate', startDate)
          .set('minDate', minDate)
          .set('maxDate', maxDate)
      );
    }
  }, [segment, state, setState, policy, canBackDate]);

  const handleStartDate = useCallback(
    ({ value: startDate }) => {
      setState(state.set('startDate', startDate));
    },
    [setState, state]
  );

  const handleRadioChange = useCallback(
    (e) => {
      const { startDate, minDate, maxDate } = getStartMinMaxDate({
        policy,
        editCurrentSegment: e.target.value === changeType.EditSelectedSegment,
        currentSegment: segment,
        canBackDate
      });
      setState(
        state
          .set('changeType', e.target.value)
          .set('startDate', startDate)
          .set('minDate', minDate)
          .set('maxDate', maxDate)
      );
    },
    [setState, state, canBackDate, policy, segment]
  );

  const handleSegmentChange = useCallback(
    (policyDetails) => {
      const hasDateViolations = getDateViolations({
        isEdit: state.get('changeType') === changeType.EditSelectedSegment,
        policy,
        segment,
        startDate: state.get('startDate'),
        minDate: state.get('minDate'),
        maxDate: state.get('maxDate'),
        toast
      });
      if (!hasDateViolations) {
        // If we're turning on inventory then set the discountInventoryScoreAddedDate
        if (!segment.global.discountInventoryScore && policyDetails.global.discountInventoryScore) {
          policyDetails.global.discountInventoryScoreAddedDate = dateFnsFormat(new Date(), 'yyyy-MM-dd');
        }

        /*
         * Logic with multi policy discount & attached endorsements
         * - Multi policy discount is used in rating for Home policies only
         * - Based on the multi policy discount, we will try to correct the following nodes if they are wrong:
         * attachedHomeowners, attachedRenters, attachedAuto, includeUmbrella
         * - We will not try to correct the above nodes if the multi policy discount is manually changed
         * because maybe we want to honor the price we gave incorrectly, want to give the discount now for a future endorsement,
         * or endorse ahead of a cancellation.
         * - If the multi policy discount was manually changed, but is mismatched from the attached endorsements, then we will
         * notify the user of this in the policy preview modal
         */

        // check if the MPD was manually changed
        const manuallySetMultiPolicyDiscount =
          policyDetails?.multiPolicyDiscount !== initialValues?.multiPolicyDiscount;

        // get the correct value for the MPD
        const calculatedMultiPolicyDiscount =
          policy.policyType === policyTypes.Home
            ? mapHomeDiscountValues({
                ...policyDetails,
                attachedRenters: autoPolicyDetails?.attachedRenters || autoPolicyDetails?.includeRenters,
                hasSecondHome: !!secondHome // we apply secondary home discount to all homes
              })
            : mapAutoDiscountValues({
                ...policyDetails,
                includeUmbrella: homePolicyDetails?.includeUmbrella
              });

        // use either the corrected value or incoming value based on if it was manually changed
        const multiPolicyDiscount = manuallySetMultiPolicyDiscount
          ? policyDetails.multiPolicyDiscount
          : calculatedMultiPolicyDiscount;

        //  based on the MPD we are using and if it was manually changed, calculate attachedHomeowners, attachedRenters, includeUmbrella, and attachedAuto values
        const initialAttachments = getAttachedEndorsements(initialValues, policy.policyType);
        const incomingAttachments = getAttachedEndorsements(policyDetails, policy.policyType);
        const calculatedAttachments = mapDiscountToInputs({
          multiPolicyDiscount
        });
        const attachmentsCorrectedInSubmit = !_isEqual(incomingAttachments, calculatedAttachments);

        const correctedMPD =
          !manuallySetMultiPolicyDiscount && calculatedMultiPolicyDiscount !== policyDetails.multiPolicyDiscount;

        // Create the description of the corrections we did to show in the Policy Preview modal
        const descriptionOfCorrectionsArray = [];
        const descriptionOfMismatchedDataArray = [];

        if (correctedMPD) {
          const description = getCorrectedMPDDescription({
            oldValue: policyDetails.multiPolicyDiscount,
            newValue: multiPolicyDiscount
          });
          if (description) {
            descriptionOfCorrectionsArray.push(description);
          }
        } else if (calculatedMultiPolicyDiscount !== multiPolicyDiscount && manuallySetMultiPolicyDiscount) {
          // we would have corrected the MPD to something different but it was manually set
          descriptionOfMismatchedDataArray.push(
            getMismatchedMPDDescription({ multiPolicyDiscount, calculatedMultiPolicyDiscount })
          );
        }

        if (attachmentsCorrectedInSubmit) {
          // description of attached endorsements that we corrected
          const description = getCorrectedAttachmentsDescription({
            oldValues: initialAttachments,
            newValues: calculatedAttachments,
            policyType: policy.policyType
          });
          if (description) {
            descriptionOfCorrectionsArray.push(description);
          }
        } else if (manuallySetMultiPolicyDiscount && !_isEqual(incomingAttachments, calculatedAttachments)) {
          // we would have corrected the attached endorsements, but didn't because MPD was manually changed
          const description = getMismatchedAttachedEndorsementsFromMPD({
            multiPolicyDiscount,
            calculatedAttachments,
            incomingAttachments,
            policyType: policy.policyType
          });
          if (description) {
            descriptionOfMismatchedDataArray.push(description);
          }
        }

        const automatedFixesDescription =
          descriptionOfCorrectionsArray?.length > 0 ? descriptionOfCorrectionsArray.join('. ') : undefined;
        const mismatchedDataDescription =
          descriptionOfMismatchedDataArray?.length > 0 ? descriptionOfMismatchedDataArray.join('. ') : undefined;

        onSegmentPreview(
          {
            ...policyDetails,
            global: policyDetails?.global
              ? {
                  ...policyDetails.global,
                  affinity: segment.global.affinity && affinityLabelToCode(segment.global.affinity)
                }
              : null,
            umbrellaCoverage: formatUmbrellaValues(policyDetails.umbrellaCoverage, policyDetails.includeUmbrella),
            scheduledPersonalProperty: formatInput(policyDetails.scheduledPersonalProperty),
            home: formatHomeValues(policyDetails.home),
            startDate: awsDateFormatter(state.get('startDate')),
            multiPolicyDiscount,
            ...mapDiscountToInputs({
              multiPolicyDiscount
            }),
            autoCoverage:
              policyDetails.autoCoverage && policy.policyType === policyTypes.Auto
                ? {
                    ...policyDetails.autoCoverage,
                    policyLimitPIPWL: policyDetails?.drivers?.some((d) => d.waivedPIPWL) ? 'S/WLW' : 'S'
                  }
                : null
          },
          state.get('changeType'),
          initialValues,
          automatedFixesDescription,
          mismatchedDataDescription
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSegmentPreview, state, homePolicyDetails, segment, policy, toast]
  );

  if (!segment || !policy) {
    return <Loading />;
  }

  const validate = async (values) => {
    try {
      const schema =
        policy.policyType !== policyTypes.Auto
          ? homeValidation({ policies, isAdvancedConnectedHome, savedSegment: segment, session })
          : autoValidation({ policies });
      await schema.validate(values, {
        abortEarly: false,
        context: { ...values, state: geographicState, initialValues, canAddCarsManually: session.canAddCarsManually }
      });
    } catch (errors) {
      return yupToFormErrors(errors);
    }
  };
  return (
    <Formik
      key={`${segment.segmentId}-${policy.version}`}
      initialValues={initialValues}
      validateOnBlur={false}
      onSubmit={handleSegmentChange}
      validate={validate}
    >
      <DetailsForm
        loadingPreview={loadingPreview}
        handleRadioChange={handleRadioChange}
        handleStartDate={handleStartDate}
        state={state}
        repEmail={repEmail}
      />
    </Formik>
  );
});

PolicyDetails.propTypes = {
  loadingPreview: PropTypes.bool.isRequired,
  onSegmentPreview: PropTypes.func.isRequired,
  repEmail: PropTypes.string.isRequired
};

export default withStyles(styles)(PolicyDetails);
