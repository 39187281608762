import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useFormikContext } from 'formik';
import classNames from 'classnames';
import { lookupsJson, hideHighestEducationStates, hideGenderStates } from '@ourbranch/lookups';

import { Label } from 'core/components/label';
import Form from 'core/components/form';
import CollapsibleCard from 'core/components/collapsible-card';
import RemoveButton from 'core/components/remove-button';
import useStyles from './person.styles';

function Applicant({ index, fieldId: id, fieldName, item, removeFromList, onRemove, initialFold, onFold, disabled }) {
  const classes = useStyles();
  const { values } = useFormikContext();
  const state = values.home.homeLocation.state;

  return (
    <CollapsibleCard
      content={
        <>
          <Label
            type="infoSubtitle"
            style={{
              display: 'inline',
              marginRight: 8
            }}
          >{`${item.firstName} ${item.lastName}`}</Label>
          {item.isPrimary ? (
            <Label type="infoCardTitle">Applicant</Label>
          ) : item.isCoApplicant ? (
            <Label type="infoCardTitle">Co applicant</Label>
          ) : null}
        </>
      }
      contentRight={
        !item.isPrimary && (
          <RemoveButton disabled={disabled} mode="big" onClick={() => onRemove(index, removeFromList)} />
        )
      }
      initialOpen={initialFold}
      onChange={(val) => onFold(index, val)}
    >
      <div className={classNames(classes.container, classes.coApplicantContainer)}>
        <Form
          disabled={disabled}
          data={[
            {
              component: Grid,
              props: {
                key: 'container',
                container: true,
                justify: 'space-around',
                alignItems: 'flex-start',
                spacing: 2
              },
              children: [
                {
                  id,
                  name: `${fieldName}.firstName`,
                  type: 'string',
                  label: 'First Name',
                  mode: 'light',
                  width: 3
                },
                {
                  id,
                  name: `${fieldName}.middleName`,
                  type: 'string',
                  label: 'Middle Name',
                  mode: 'light',
                  width: 3
                },
                {
                  id,
                  name: `${fieldName}.lastName`,
                  type: 'string',
                  label: 'Last Name',
                  mode: 'light',
                  width: 3
                },
                {
                  id,
                  name: `${fieldName}.dateOfBirth`,
                  type: 'date',
                  label: 'Date of Birth',
                  mode: 'light',
                  width: 3
                },
                {
                  component: Grid,
                  props: {
                    key: 'second-container',
                    container: true,
                    spacing: 2
                  },
                  children: [
                    {
                      id,
                      name: `${fieldName}.suffix`,
                      type: 'string',
                      label: 'Suffix',
                      mode: 'light',
                      width: 1
                    },
                    ...(!hideGenderStates.includes(state)
                      ? [
                          {
                            id,
                            name: `${fieldName}.gender`,
                            type: 'select',
                            label: 'Gender',
                            mode: 'light',
                            options: lookupsJson.gender,
                            width: 2
                          }
                        ]
                      : []),
                    {
                      id,
                      name: `${fieldName}.maritalStatus`,
                      type: 'select',
                      label: 'Marital Status',
                      mode: 'light',
                      options: lookupsJson.maritalStatus,
                      width: 3
                    },
                    ...(!hideHighestEducationStates.includes(state)
                      ? [
                          {
                            id,
                            name: `${fieldName}.highestEducation`,
                            type: 'select',
                            label: 'Highest Education Achieved',
                            mode: 'light',
                            options: lookupsJson.highestEducation,
                            width: 6
                          }
                        ]
                      : [])
                  ]
                }
              ]
            }
          ]}
        />
      </div>
      {(item.isPrimary || item.isCoApplicant) && item.insuranceScore && (
        <Grid container alignItems="center" justify="flex-end" className={classes.cardFooter}>
          <Grid container item xs={3} direction="column" alignItems="flex-end">
            <Label type="infoLabel">Insurance Score</Label>
            <Label type="infoValue">
              {Number(item.insuranceScore?.homeTotal?.toFixed(1) || item.insuranceScore?.total?.toFixed(1))}
            </Label>
          </Grid>
        </Grid>
      )}
    </CollapsibleCard>
  );
}

Applicant.propTypes = {
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  fieldId: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  item: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    middleName: PropTypes.string,
    lastName: PropTypes.string.isRequired,
    dateOfBirth: PropTypes.string,
    addDate: PropTypes.string,
    ageFirstLicensed: PropTypes.number,
    driversLicenseNumber: PropTypes.string,
    driversLicenseState: PropTypes.string,
    fullTimeStudent: PropTypes.bool,
    gender: PropTypes.string,
    goodStudent: PropTypes.bool,
    highestEducation: PropTypes.string,
    isCoApplicant: PropTypes.bool,
    isPrimary: PropTypes.bool,
    maritalStatus: PropTypes.string,
    schoolLocation: PropTypes.object,
    schoolName: PropTypes.string,
    suffix: PropTypes.string,
    insuranceScore: PropTypes.object
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
  removeFromList: PropTypes.func.isRequired,
  initialFold: PropTypes.bool.isRequired,
  onFold: PropTypes.func.isRequired
};

Applicant.defaultProps = {
  disabled: false
};

export default Applicant;
