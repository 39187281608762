export default {
  Offer: {
    AbodeProPlan: 'AbodeProPlan',
    ConnectedHome: 'ConnectedHome',
    NewConnectedHomeSignUpCustomer: 'NewConnectedHomeSignUpCustomer'
  },
  Policy: {
    CrossSellEligibility: 'CrossSellEligibility',
    SignedConversionDocuments: 'SignedConversionDocuments',
    PendingThirdPartyPurchases: 'PendingThirdPartyPurchases'
  },
  Customer: {
    CrossSellEligibility: 'CrossSellEligibility'
  }
};
