/* eslint-disable func-names */
import * as Yup from 'yup';
import { getIn, validateYupSchema, yupToFormErrors } from 'formik';
import { validUSState, requiredString, validAffinityCode } from 'common/helpers/yup-helpers';
import { isValidVin } from 'core/helpers/vin-validator';

Yup.addMethod(Yup.string, 'requiredString', requiredString);
Yup.addMethod(Yup.mixed, 'validUSState', validUSState);
Yup.addMethod(Yup.mixed, 'validAffinityCode', validAffinityCode);

const shouldValidate = (values, targetKeys, prefix = '') => {
  if (!targetKeys || !targetKeys.length) {
    return false;
  }
  return targetKeys.find((key) => getIn(values, `${prefix}${key}`));
};

const validationSchema = (errorCodes, values, allowedStates, isAgency) => {
  const base = Yup.object().shape({
    firstName: Yup.string().requiredString('First name is required'),
    lastName: Yup.string().requiredString('Last name is required'),
    address: Yup.string().requiredString('Address is required'),
    state: Yup.string()
      .requiredString('State is required')
      .validUSState('state')
      .test('is-licensed-in-state', 'You are not licensed to quote in this state', (val) => {
        return isAgency ? allowedStates.includes(val) : true;
      }),
    zip: Yup.string().requiredString('Zip is required'),
    city: Yup.string().requiredString('City is required'),
    leadSource: Yup.string(),
    fcraDisclaimer: Yup.boolean().oneOf([true], 'Must agree to FCRA disclaimer'),
    driversLicenseState: Yup.string().validUSState('driversLicenseState'),
    affinity: Yup.string().validAffinityCode('affinityPartnersQuoteForm').nullable(),
    phone: Yup.string().min(10, 'Must be at least 10 digits').nullable(),
    additionalPhoneNumbers: Yup.array()
      .of(
        Yup.object().shape({
          phoneNumber: Yup.string()
            .required('Required or click trash to remove')
            .min(10, 'Must be at least 10 digits')
            .test(
              'check-for-primary-phone',
              'You cannot add a secondary phone without a primary phone',
              (secondaryPhone) => {
                return (secondaryPhone && secondaryPhone.length > 0 && values.phone?.length > 0) || !secondaryPhone;
              }
            ),
          note: Yup.string().max(50, 'Must be less than 50 characters').nullable()
        })
      )
      .nullable()
  });
  let final = base;

  // if any values in the priorAddress section are filled in, validate for all values in that section
  const priorAddress = shouldValidate(values, Object.keys(values.priorAddress || []), 'priorAddress.');
  const addingCar = values.VIN;
  const isNewConstruction = values.isNewConstruction;

  if (addingCar) {
    final = final.concat(
      Yup.object().shape({
        VIN: Yup.string()
          .ensure()
          .test('is-valid-vin', 'Please enter a valid car VIN', (val) => isValidVin(val))
      })
    );
  }

  if (priorAddress) {
    final = final.concat(
      Yup.object().shape({
        priorAddress: Yup.object().shape({
          address: Yup.string().requiredString('Address is required'),
          state: Yup.string().requiredString('State is Required').validUSState('priorAddress.state'),
          zip: Yup.string().requiredString('Zip is required'),
          city: Yup.string().requiredString('City is required')
        })
      })
    );
  }

  // validation required if house is new construction
  if (isNewConstruction) {
    final = final.concat(
      Yup.object().shape({
        priorAddress: Yup.object().shape({
          address: Yup.string().requiredString('Address is required'),
          state: Yup.string().requiredString('State is Required').validUSState('priorAddress.newConstruction.state'),
          zip: Yup.string().requiredString('Zip is required'),
          city: Yup.string().requiredString('City is required')
        }),
        home: Yup.object().shape({
          basementSqFt: Yup.string().requiredString('Square footage of basement is required'),
          basementType: Yup.string().requiredString('Basement type is required'),
          constructionType: Yup.string().requiredString('Construction type is required'),
          exteriorWallType: Yup.string().requiredString('Exterior wall type is required'),
          garageCarCapacity: Yup.string().requiredString('Garage car capacity is required'),
          garageType: Yup.string().requiredString('Garage type is required'),
          homeQuality: Yup.string().requiredString('Home quality is required'),
          numFamiliesInDwelling: Yup.string().requiredString('Number of families in the dwelling is required'),
          numFireplaces: Yup.string().requiredString('Number of fireplaces is required'),
          numFullBathrooms: Yup.string().requiredString('Number of full bathrooms is required'),
          numHalfBathrooms: Yup.string().requiredString('Number of half bathrooms is required'),
          numStories: Yup.string().requiredString('Number of stories is required'),
          numWoodBurningStoves: Yup.string().requiredString('Number of wood burning stoves is required'),
          purchaseDate: Yup.string().requiredString('Purchase date is required'),
          roofOriginal: Yup.string().requiredString('Roof originality is required'),
          roofShape: Yup.string().requiredString('Roof shape is required'),
          roofType: Yup.string().requiredString('Roof type is required'),
          roofYear: Yup.string().requiredString('Roof year is required'),
          sqFt: Yup.string().requiredString('Square footage is required'),
          typeOfHome: Yup.string().requiredString('Type of home is required'),
          yearBuilt: Yup.string().requiredString('Build year is required')
        })
      })
    );
  }
  return final;
};

export const validateQuoteForm = (errorCodes, values, allowedStates, isAgency) => {
  return validateYupSchema(values, validationSchema(errorCodes, values, allowedStates, isAgency))
    .then(() => {
      return null;
    })
    .catch((e) => {
      if (e.name && e.name === 'ValidationError') {
        throw yupToFormErrors(e);
      }
      throw e;
    });
};
